@import "../../styles/grid";

$breakpoints: (
  xs: 0,
  sm: $min-mobile,
  md: $min-tablet,
  xl: $min-desktop,
);

.grid {
  @include make-container();
}
.grid__row {
  @include grid-row();
}

.grid__row_valign_center {
  align-items: center;
}
.grid__row_valign_top {
  align-items: flex-start;
}
.grid__row_valign_bottom {
  align-items: flex-end;
}
.grid__row_valign_baseline {
  align-items: baseline;
}

@media (min-width: $min-desktop) {
  .grid_reverse {
    flex-direction: row-reverse;
  }
}


%grid-col {
  position: relative;
  width: 100%;
  padding-right: $gutter-desktop / 2;
  padding-left: $gutter-desktop / 2;
}

@each $breakpoint, $min in $breakpoints {
  $infix: $breakpoint;

  @for $i from 1 through $grid-column-count {
    .grid__col_#{$infix}_#{$i} {
      @extend %grid-col;
    }
  }
  @media (min-width: $min) {
    @for $i from 1 through $grid-column-count {
      .grid__col_#{$infix}_#{$i} {
        width: convertify($i);
        flex-shrink: 0;
      }
    }
    @for $i from 0 through ($grid-column-count - 1) {
      .grid__col_offset-#{$infix}_#{$i} {
        @include grid-offset-left($i);
      }
    }
  }
}
