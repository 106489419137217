@import "../../styles/variables";

.auth-header-text {
  color: $color-gray3;
  font-size: 1rem;
  line-height: 1.25;
  padding: 20px;

  a {
    color: $color-primary;
  }
  span {
    display: none;
  }
}

@media (min-width: $min-tablet) {
  .auth-header-text {
    span {
      display: inline;
    }
  }
}
