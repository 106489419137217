@import url(https://use.typekit.net/ctn7gqi.css);
@-webkit-keyframes fade-in-app {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-app {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.app {
  -webkit-animation: 1400ms fade-in-app;
          animation: 1400ms fade-in-app; }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes zoom-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoom-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.015);
            transform: scale(1.015); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.015);
            transform: scale(1.015); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes loading {
  from {
    left: -30%;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

@keyframes loading {
  from {
    left: -30%;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

.icon_dashboard,
.icon_user,
.icon_arrow,
.icon_arrow-back,
.icon_canvas-business,
.icon_canvas-value,
.icon_canvas-lean,
.icon_cog,
.icon_share,
.icon_print {
  fill: #15151a; }

.icon_spin {
  width: 1.5rem;
  height: 1.5rem;
  fill: #3b3beb;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear; }

@-webkit-keyframes cookie-showup {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  66% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes cookie-showup {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  66% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.cookie-badge {
  color: #fff;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px 80px 20px 40px;
  background: #3b3beb;
  -webkit-animation: 2s ease cookie-showup;
          animation: 2s ease cookie-showup; }
  .cookie-badge a {
    color: #fff;
    text-decoration: underline; }

.cookie-badge__close {
  position: absolute;
  right: 20px;
  top: 50%;
  width: 30px;
  height: 30px;
  margin: -15px 0 0;
  padding: 0;
  background: transparent;
  border: none; }
  .cookie-badge__close .icon_cross {
    width: 16px;
    height: 16px;
    fill: #fff; }

.grid {
  width: 100%; }

.grid__row {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: -30px;
  margin-right: -30px;
  flex-direction: row; }

.grid__row_valign_center {
  align-items: center; }

.grid__row_valign_top {
  align-items: flex-start; }

.grid__row_valign_bottom {
  align-items: flex-end; }

.grid__row_valign_baseline {
  align-items: baseline; }

@media (min-width: 1080px) {
  .grid_reverse {
    flex-direction: row-reverse; } }

.grid__col_xs_1, .grid__col_xs_2, .grid__col_xs_3, .grid__col_xs_4, .grid__col_xs_5, .grid__col_xs_6, .grid__col_xs_7, .grid__col_xs_8, .grid__col_xs_9, .grid__col_xs_10, .grid__col_xs_11, .grid__col_xs_12, .grid__col_sm_1, .grid__col_sm_2, .grid__col_sm_3, .grid__col_sm_4, .grid__col_sm_5, .grid__col_sm_6, .grid__col_sm_7, .grid__col_sm_8, .grid__col_sm_9, .grid__col_sm_10, .grid__col_sm_11, .grid__col_sm_12, .grid__col_md_1, .grid__col_md_2, .grid__col_md_3, .grid__col_md_4, .grid__col_md_5, .grid__col_md_6, .grid__col_md_7, .grid__col_md_8, .grid__col_md_9, .grid__col_md_10, .grid__col_md_11, .grid__col_md_12, .grid__col_xl_1, .grid__col_xl_2, .grid__col_xl_3, .grid__col_xl_4, .grid__col_xl_5, .grid__col_xl_6, .grid__col_xl_7, .grid__col_xl_8, .grid__col_xl_9, .grid__col_xl_10, .grid__col_xl_11, .grid__col_xl_12 {
  position: relative;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px; }

@media (min-width: 0) {
  .grid__col_xs_1 {
    width: 8.33333%;
    flex-shrink: 0; }
  .grid__col_xs_2 {
    width: 16.66667%;
    flex-shrink: 0; }
  .grid__col_xs_3 {
    width: 25%;
    flex-shrink: 0; }
  .grid__col_xs_4 {
    width: 33.33333%;
    flex-shrink: 0; }
  .grid__col_xs_5 {
    width: 41.66667%;
    flex-shrink: 0; }
  .grid__col_xs_6 {
    width: 50%;
    flex-shrink: 0; }
  .grid__col_xs_7 {
    width: 58.33333%;
    flex-shrink: 0; }
  .grid__col_xs_8 {
    width: 66.66667%;
    flex-shrink: 0; }
  .grid__col_xs_9 {
    width: 75%;
    flex-shrink: 0; }
  .grid__col_xs_10 {
    width: 83.33333%;
    flex-shrink: 0; }
  .grid__col_xs_11 {
    width: 91.66667%;
    flex-shrink: 0; }
  .grid__col_xs_12 {
    width: 100%;
    flex-shrink: 0; }
  .grid__col_offset-xs_0 {
    margin-left: 0%; }
  .grid__col_offset-xs_1 {
    margin-left: 8.33333%; }
  .grid__col_offset-xs_2 {
    margin-left: 16.66667%; }
  .grid__col_offset-xs_3 {
    margin-left: 25%; }
  .grid__col_offset-xs_4 {
    margin-left: 33.33333%; }
  .grid__col_offset-xs_5 {
    margin-left: 41.66667%; }
  .grid__col_offset-xs_6 {
    margin-left: 50%; }
  .grid__col_offset-xs_7 {
    margin-left: 58.33333%; }
  .grid__col_offset-xs_8 {
    margin-left: 66.66667%; }
  .grid__col_offset-xs_9 {
    margin-left: 75%; }
  .grid__col_offset-xs_10 {
    margin-left: 83.33333%; }
  .grid__col_offset-xs_11 {
    margin-left: 91.66667%; } }

@media (min-width: 480px) {
  .grid__col_sm_1 {
    width: 8.33333%;
    flex-shrink: 0; }
  .grid__col_sm_2 {
    width: 16.66667%;
    flex-shrink: 0; }
  .grid__col_sm_3 {
    width: 25%;
    flex-shrink: 0; }
  .grid__col_sm_4 {
    width: 33.33333%;
    flex-shrink: 0; }
  .grid__col_sm_5 {
    width: 41.66667%;
    flex-shrink: 0; }
  .grid__col_sm_6 {
    width: 50%;
    flex-shrink: 0; }
  .grid__col_sm_7 {
    width: 58.33333%;
    flex-shrink: 0; }
  .grid__col_sm_8 {
    width: 66.66667%;
    flex-shrink: 0; }
  .grid__col_sm_9 {
    width: 75%;
    flex-shrink: 0; }
  .grid__col_sm_10 {
    width: 83.33333%;
    flex-shrink: 0; }
  .grid__col_sm_11 {
    width: 91.66667%;
    flex-shrink: 0; }
  .grid__col_sm_12 {
    width: 100%;
    flex-shrink: 0; }
  .grid__col_offset-sm_0 {
    margin-left: 0%; }
  .grid__col_offset-sm_1 {
    margin-left: 8.33333%; }
  .grid__col_offset-sm_2 {
    margin-left: 16.66667%; }
  .grid__col_offset-sm_3 {
    margin-left: 25%; }
  .grid__col_offset-sm_4 {
    margin-left: 33.33333%; }
  .grid__col_offset-sm_5 {
    margin-left: 41.66667%; }
  .grid__col_offset-sm_6 {
    margin-left: 50%; }
  .grid__col_offset-sm_7 {
    margin-left: 58.33333%; }
  .grid__col_offset-sm_8 {
    margin-left: 66.66667%; }
  .grid__col_offset-sm_9 {
    margin-left: 75%; }
  .grid__col_offset-sm_10 {
    margin-left: 83.33333%; }
  .grid__col_offset-sm_11 {
    margin-left: 91.66667%; } }

@media (min-width: 720px) {
  .grid__col_md_1 {
    width: 8.33333%;
    flex-shrink: 0; }
  .grid__col_md_2 {
    width: 16.66667%;
    flex-shrink: 0; }
  .grid__col_md_3 {
    width: 25%;
    flex-shrink: 0; }
  .grid__col_md_4 {
    width: 33.33333%;
    flex-shrink: 0; }
  .grid__col_md_5 {
    width: 41.66667%;
    flex-shrink: 0; }
  .grid__col_md_6 {
    width: 50%;
    flex-shrink: 0; }
  .grid__col_md_7 {
    width: 58.33333%;
    flex-shrink: 0; }
  .grid__col_md_8 {
    width: 66.66667%;
    flex-shrink: 0; }
  .grid__col_md_9 {
    width: 75%;
    flex-shrink: 0; }
  .grid__col_md_10 {
    width: 83.33333%;
    flex-shrink: 0; }
  .grid__col_md_11 {
    width: 91.66667%;
    flex-shrink: 0; }
  .grid__col_md_12 {
    width: 100%;
    flex-shrink: 0; }
  .grid__col_offset-md_0 {
    margin-left: 0%; }
  .grid__col_offset-md_1 {
    margin-left: 8.33333%; }
  .grid__col_offset-md_2 {
    margin-left: 16.66667%; }
  .grid__col_offset-md_3 {
    margin-left: 25%; }
  .grid__col_offset-md_4 {
    margin-left: 33.33333%; }
  .grid__col_offset-md_5 {
    margin-left: 41.66667%; }
  .grid__col_offset-md_6 {
    margin-left: 50%; }
  .grid__col_offset-md_7 {
    margin-left: 58.33333%; }
  .grid__col_offset-md_8 {
    margin-left: 66.66667%; }
  .grid__col_offset-md_9 {
    margin-left: 75%; }
  .grid__col_offset-md_10 {
    margin-left: 83.33333%; }
  .grid__col_offset-md_11 {
    margin-left: 91.66667%; } }

@media (min-width: 1080px) {
  .grid__col_xl_1 {
    width: 8.33333%;
    flex-shrink: 0; }
  .grid__col_xl_2 {
    width: 16.66667%;
    flex-shrink: 0; }
  .grid__col_xl_3 {
    width: 25%;
    flex-shrink: 0; }
  .grid__col_xl_4 {
    width: 33.33333%;
    flex-shrink: 0; }
  .grid__col_xl_5 {
    width: 41.66667%;
    flex-shrink: 0; }
  .grid__col_xl_6 {
    width: 50%;
    flex-shrink: 0; }
  .grid__col_xl_7 {
    width: 58.33333%;
    flex-shrink: 0; }
  .grid__col_xl_8 {
    width: 66.66667%;
    flex-shrink: 0; }
  .grid__col_xl_9 {
    width: 75%;
    flex-shrink: 0; }
  .grid__col_xl_10 {
    width: 83.33333%;
    flex-shrink: 0; }
  .grid__col_xl_11 {
    width: 91.66667%;
    flex-shrink: 0; }
  .grid__col_xl_12 {
    width: 100%;
    flex-shrink: 0; }
  .grid__col_offset-xl_0 {
    margin-left: 0%; }
  .grid__col_offset-xl_1 {
    margin-left: 8.33333%; }
  .grid__col_offset-xl_2 {
    margin-left: 16.66667%; }
  .grid__col_offset-xl_3 {
    margin-left: 25%; }
  .grid__col_offset-xl_4 {
    margin-left: 33.33333%; }
  .grid__col_offset-xl_5 {
    margin-left: 41.66667%; }
  .grid__col_offset-xl_6 {
    margin-left: 50%; }
  .grid__col_offset-xl_7 {
    margin-left: 58.33333%; }
  .grid__col_offset-xl_8 {
    margin-left: 66.66667%; }
  .grid__col_offset-xl_9 {
    margin-left: 75%; }
  .grid__col_offset-xl_10 {
    margin-left: 83.33333%; }
  .grid__col_offset-xl_11 {
    margin-left: 91.66667%; } }

.footer {
  color: #c2c2d0;
  padding: 40px 0; }
  .footer a {
    color: #c2c2d0;
    text-decoration: none; }
    .footer a:hover {
      text-decoration: underline; }

.footer__logo {
  text-align: center;
  margin-bottom: 20px; }
  .footer__logo svg,
  .footer__logo img {
    width: 40px;
    height: 30px; }

.footer__copy {
  text-align: center;
  margin-bottom: 20px; }

.footer__menus {
  text-align: center; }

.footer__menu {
  text-align: left;
  display: inline-block;
  vertical-align: top;
  list-style: none;
  min-width: 100px;
  margin: 0 40px 0 0;
  padding: 0; }
  .footer__menu li {
    margin-bottom: 0.5em; }
  .footer__menu a {
    font-weight: 500; }
  .footer__menu:last-child {
    margin-right: 0; }

@media (min-width: 720px) {
  .footer {
    padding-top: 50px;
    padding-bottom: 50px; }
  .footer__logo {
    text-align: left; }
  .footer__copy {
    margin-bottom: 0;
    text-align: left; }
  .footer__menus {
    text-align: left; }
  .footer__menu {
    margin-right: 60px; }
    .footer__menu:last-child {
      margin-right: 0; } }

@media (min-width: 1080px) {
  .footer__logo {
    float: left; }
    .footer__logo svg,
    .footer__logo img {
      width: 60px;
      height: 45px; }
  .footer__copy {
    margin-left: 100px; }
  .footer__menu {
    margin-right: 100px; }
    .footer__menu:last-child {
      margin-right: 0; } }

.layout__container {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background: #fff;
  padding-top: 60px;
  padding-bottom: 90px;
  margin-bottom: 315px; }

.layout__inner {
  position: relative;
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 30px; }

.layout__footer {
  color: #6a6a80;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 315px;
  z-index: -1;
  background: #15151a; }

.layout_fluid .layout__inner {
  max-width: none; }

.layout_no-overflow .layout__container {
  overflow: auto; }
  .layout_no-overflow .layout__container .layout__inner {
    min-width: 1060px; }

.layout_type_canvas .layout__container {
  height: 100vh;
  overflow: auto; }

.layout_type_static-page .layout__container .layout__inner {
  max-width: 800px;
  margin: 0 auto; }

.layout_background_gradient .layout__container {
  background: -webkit-gradient(linear, right bottom, left top, from(#d299c2), to(#fef9d7));
  background: -webkit-linear-gradient(bottom right, #d299c2 0%, #fef9d7 100%);
  background: linear-gradient(to top left, #d299c2 0%, #fef9d7 100%); }

.layout_background_gradient_0 .layout__container {
  background: -webkit-gradient(linear, left bottom, left top, from(#cfd9df), to(#e2ebf0));
  background: -webkit-linear-gradient(bottom, #cfd9df 0%, #e2ebf0 100%);
  background: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%); }

.layout_background_gradient_1 .layout__container {
  background: -webkit-gradient(linear, left bottom, left top, from(#a18cd1), to(#fbc2eb));
  background: -webkit-linear-gradient(bottom, #a18cd1 0%, #fbc2eb 100%);
  background: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%); }

.layout_background_gradient_2 .layout__container {
  background: -webkit-gradient(linear, left top, left bottom, from(#ff867a), color-stop(21%, #ff8c7f), color-stop(52%, #f99185), color-stop(78%, #cf556c), to(#b12a5b));
  background: -webkit-linear-gradient(top, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%, #b12a5b 100%);
  background: linear-gradient(to bottom, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%, #b12a5b 100%); }

.layout_background_gradient_3 .layout__container {
  background: -webkit-gradient(linear, right bottom, left top, from(#d299c2), to(#fef9d7));
  background: -webkit-linear-gradient(bottom right, #d299c2 0%, #fef9d7 100%);
  background: linear-gradient(to top left, #d299c2 0%, #fef9d7 100%); }

.layout_background_gradient_4 .layout__container {
  background: -webkit-gradient(linear, left bottom, left top, from(#fad0c4), to(#ffd1ff));
  background: -webkit-linear-gradient(bottom, #fad0c4 0%, #ffd1ff 100%);
  background: linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%); }

.layout_background_gradient_5 .layout__container {
  background: -webkit-linear-gradient(75deg, #13547a 0%, #80d0c7 100%);
  background: linear-gradient(15deg, #13547a 0%, #80d0c7 100%); }

.layout_background_gradient_6 .layout__container {
  background: -webkit-gradient(linear, left bottom, left top, from(#09203f), to(#537895));
  background: -webkit-linear-gradient(bottom, #09203f 0%, #537895 100%);
  background: linear-gradient(to top, #09203f 0%, #537895 100%); }

.layout_background_gradient_7 .layout__container {
  background: -webkit-linear-gradient(330deg, #84fab0 0%, #8fd3f4 100%);
  background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%); }

.layout_background_gradient_8 .layout__container {
  background: -webkit-gradient(linear, right bottom, left top, from(#fddb92), to(#d1fdff));
  background: -webkit-linear-gradient(bottom right, #fddb92 0%, #d1fdff 100%);
  background: linear-gradient(to top left, #fddb92 0%, #d1fdff 100%); }

.layout_background_gradient_9 .layout__container {
  background: -webkit-gradient(linear, left top, right top, from(#e4afcb), color-stop(0%, #b8cbb8), color-stop(0%, #b8cbb8), color-stop(30%, #e2c58b), color-stop(64%, #c2ce9c), to(#7edbdc));
  background: -webkit-linear-gradient(left, #e4afcb 0%, #b8cbb8 0%, #b8cbb8 0%, #e2c58b 30%, #c2ce9c 64%, #7edbdc 100%);
  background: linear-gradient(to right, #e4afcb 0%, #b8cbb8 0%, #b8cbb8 0%, #e2c58b 30%, #c2ce9c 64%, #7edbdc 100%); }

.layout_background_pic_magnus .layout__container {
  background: #e84700 url(/static/media/hodgepodge.aeed2cd6.png) repeat 50% 50%; }

@media (min-width: 720px) {
  .layout__container {
    margin-bottom: 300px; }
  .layout__footer {
    height: 300px; }
  .layout__inner {
    padding-left: 30px;
    padding-right: 30px; } }

@media (min-width: 1080px) {
  .layout__container {
    margin-bottom: 200px; }
  .layout__footer {
    height: 200px; }
  .layout__inner {
    padding-left: 60px;
    padding-right: 60px; }
  .layout_type_canvas .layout__container {
    height: auto;
    overflow: hidden; } }

.landing__bg {
  position: absolute;
  top: 450px;
  left: 0;
  width: 100%;
  height: 846px;
  background: url(/static/media/bg.d3a27be1.jpg) no-repeat 50% 50%;
  will-change: transform; }

.landing__benefits {
  margin-bottom: 60px; }

@media (min-width: 1080px) {
  .landing__benefits {
    margin-bottom: 0; }
  .landing__benefits_reverse .grid__row {
    flex-direction: row-reverse; } }

.button {
  color: #fff;
  font: bold 1.125rem/1.125 sofia-pro, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-align: center;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding: 20px 60px;
  background: transparent;
  border: 0;
  will-change: color;
  -webkit-transition: color 200ms;
  transition: color 200ms; }
  .button:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #3b3beb;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(59, 59, 235, 0.3);
    will-change: background, border, box-shadow;
    -webkit-transition: background 200ms, border 200ms, box-shadow 200ms;
    transition: background 200ms, border 200ms, box-shadow 200ms; }
  .button:not([disabled]):hover:before {
    background: #0d0dff; }
  .button:not([disabled]):active {
    -webkit-transform: translateY(1px);
            transform: translateY(1px); }
    .button:not([disabled]):active:before {
      background: #0c0ce6;
      box-shadow: 0 1px 2px rgba(59, 59, 235, 0.3); }
  .button:focus {
    outline: none; }
  .button[disabled] {
    opacity: 0.75; }
  .button .icon {
    fill: #fff; }
  .button .icon_arrow,
  .button .icon_arrow-back {
    width: 18px;
    height: 12px;
    margin-left: 10px;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease; }
  .button .icon_arrow-back {
    margin-left: 0;
    margin-right: 10px; }
  .button .icon_dashboard {
    vertical-align: top;
    width: 18px;
    height: 18px;
    margin-right: 0.75em;
    margin-top: 1px; }
  .button .icon_plus {
    vertical-align: middle;
    width: 18px;
    height: 18px; }

.button__inner {
  position: relative; }

.button_width_full {
  width: 100%; }

.button_size_x-small {
  font-weight: 500;
  padding: 0.45em 1.334em 0.55em; }

.button_size_small {
  font-weight: 500;
  padding: 0.75em 1.5em 0.8em; }

.button_danger:before {
  background-color: #ba2e2e;
  box-shadow: 0 2px 4px rgba(186, 46, 46, 0.3); }

.button_danger:hover:not([disabled]):before {
  background-color: #c14343;
  box-shadow: 0 1px 2px rgba(186, 46, 46, 0.3); }

.button_danger:active:not([disabled]):before {
  background: #a72929;
  box-shadow: 0 1px 2px rgba(186, 46, 46, 0.3); }

.button_secondary {
  color: #3b3beb;
  font-weight: 500; }
  .button_secondary .icon {
    fill: #3b3beb; }
  .button_secondary:before {
    background-color: #f9f9fa;
    box-shadow: none; }
  .button_secondary:hover:not([disabled]):before {
    background-color: #ededf1;
    box-shadow: none; }
  .button_secondary:active:not([disabled]):before {
    background: #ededf1;
    box-shadow: none; }

.button_ghost {
  color: #3b3beb; }
  .button_ghost .icon {
    fill: #3b3beb; }
  .button_ghost:before {
    background-color: transparent;
    box-shadow: none; }
  .button_ghost:hover:not([disabled]):before {
    background-color: transparent;
    box-shadow: none; }
  .button_ghost:active:not([disabled]):before {
    background: transparent;
    box-shadow: none; }

.button_google {
  color: #3e3e4b;
  padding-left: 55px;
  padding-right: 20px; }
  .button_google .icon {
    fill: #3e3e4b; }
  .button_google:after {
    content: '';
    position: absolute;
    left: 20px;
    top: 50%;
    width: 25px;
    height: 26px;
    background: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI2IiB2aWV3Qm94PSIwIDAgMjUgMjYiIHdpZHRoPSIyNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIj48cGF0aCBkPSJtMjQuOTg4MDg1OSAxMy4yNDMyMjljMC0xLjA2NTIwNjEtLjA4NTA1ODYtMS44NDI1MjY3LS4yNjkxNDA2LTIuNjQ4NjI1OWgtMTEuOTY5OTIxOXY0LjgwNzgxNjdoNy4wMjYwNzQzYy0uMTQxNjAxNiAxLjE5NDgwOTItLjkwNjU0MyAyLjk5NDE2OC0yLjYwNjQ0NTQgNC4yMDMyNjcybC0uMDIzODI4MS4xNjA5NjE5IDMuNzg0NjY4IDIuOTc5MzgxNi4yNjIyMDcuMDI2NTk1NWMyLjQwODEwNTUtMi4yNjAwMTUzIDMuNzk2Mzg2Ny01LjU4NTIzNjcgMy43OTYzODY3LTkuNTI5Mzk3IiBmaWxsPSIjNDI4NWY0Ii8+PHBhdGggZD0ibTEyLjc0OTAyMzQgMjUuOTEwNjg3YzMuNDQyMTg3NSAwIDYuMzMxOTMzNi0xLjE1MTY0MTIgOC40NDI2NzU4LTMuMTM4MDYxbC00LjAyMzA0NjktMy4xNjY5MzljLTEuMDc2NTYyNS43NjI5MzEzLTIuNTIxNDg0MyAxLjI5NTUzNDQtNC40MTk2Mjg5IDEuMjk1NTM0NC0zLjM3MTM4NjY4IDAtNi4yMzI4MTI0Ni0yLjI1OTkxNjEtNy4yNTI4MzE5OS01LjM4MzU4NzhsLS4xNDk1MTE3Mi4wMTI5MDA4LTMuOTM1MzUxNTYgMy4wOTQ4OTMxLS4wNTE0NjQ4NS4xNDUzODE3YzIuMDk2NDg0MzggNC4yMzIwNDU4IDYuNDAyODMyMDMgNy4xMzk4Nzc4IDExLjM4OTE2MDEyIDcuMTM5ODc3OCIgZmlsbD0iIzM0YTg1MyIvPjxwYXRoIGQ9Im01LjQ5NjE5MTQxIDE1LjUxNzYzMzZjLS4yNjkxNDA2My0uODA2MDk5Mi0uNDI0OTAyMzUtMS42Njk4NTUtLjQyNDkwMjM1LTIuNTYyMjkwMSAwLS44OTI1MzQzLjE1NTc2MTcyLTEuNzU2MTkwOC40MTA3NDIxOS0yLjU2MjI5MDFsLS4wMDcxMjg5MS0uMTcxNjc5NC0zLjk4NDY2Nzk2LTMuMTQ0NjEwNjQtLjEzMDM3MTEuMDYzMDE1MjdjLS44NjQwNjI1IDEuNzU2MTkwODQtMS4zNTk4NjMyOCAzLjcyODMyMDU3LTEuMzU5ODYzMjggNS44MTU1NjQ4N3MuNDk1ODAwNzggNC4wNTkyNzQ4IDEuMzU5ODYzMjggNS44MTU0NjU3eiIgZmlsbD0iI2ZiYmMwNSIvPjxwYXRoIGQ9Im0xMi43NDkwMjM0IDUuMDA5MzY2NDFjMi4zOTM5NDUzIDAgNC4wMDg3ODkxIDEuMDUwODE2OCA0LjkyOTU4OTkgMS45Mjg5NjE4M2wzLjU5ODA0NjktMy41Njk5Mzg5M2MtMi4yMDk3NjU3LTIuMDg3MjQ0MjctNS4wODU0NDkzLTMuMzY4Mzg5MzEtOC41Mjc2MzY4LTMuMzY4Mzg5MzEtNC45ODYzMjgwOSAwLTkuMjkyNjc1NzQgMi45MDc3MzI4Mi0xMS4zODkxNjAxMiA3LjEzOTc3ODYzbDQuMTIyMTY3OTcgMy4yNTMyNzQ3N2MxLjAzNDE3OTY5LTMuMTIzNjcxNzIgMy44OTU2MDU0Ny01LjM4MzY4Njk5IDcuMjY2OTkyMTUtNS4zODM2ODY5OSIgZmlsbD0iI2ViNDMzNSIvPjwvZz48L3N2Zz4=") no-repeat 50% 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .button_google:before {
    background: #fff;
    border: 1px solid #d2d2dc;
    box-shadow: none; }
  .button_google:not([disabled]):hover:before, .button_google:not([disabled]):active:before {
    border-width: 1px;
    background: transparent;
    box-shadow: 0 2px 4px rgba(134, 134, 162, 0.15); }
  .button_google:not([disabled]):hover .icon_arrow, .button_google:not([disabled]):active .icon_arrow {
    -webkit-transform: translate3d(7px, 0, 0);
            transform: translate3d(7px, 0, 0); }
  .button_google:not([disabled]):active:before {
    box-shadow: none; }

.hero {
  margin: 90px auto 120px;
  padding: 0 10px;
  text-align: center; }

.hero__title {
  color: #15151a;
  font-family: orpheuspro, serif;
  font-size: 2.5rem;
  line-height: 1;
  margin: 0 0 0.5em; }

.hero__subtitle {
  color: #6a6a80;
  font-family: sofia-pro, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.25rem;
  font-weight: normal; }

.hero__cta {
  margin: 60px 0; }
  .hero__cta .button:before {
    box-shadow: 0 15px 30px rgba(59, 59, 235, 0.3); }
  .hero__cta .button:hover:before {
    box-shadow: 0 7px 10px rgba(59, 59, 235, 0.3); }
  .hero__cta .button:active:before {
    box-shadow: 0 4px 7px rgba(59, 59, 235, 0.3); }

@media (min-width: 720px) {
  .hero {
    margin: 90px auto 120px;
    padding: 0; }
  .hero__title {
    font-size: 3.5rem; }
  .hero__subtitle {
    font-size: 1.5rem; } }

@media (min-width: 1080px) {
  .hero {
    margin-top: 150px; }
  .hero__title {
    font-size: 4rem; } }

.section {
  margin: 30px 0; }

.section_spacing_none {
  margin-top: 0;
  margin-bottom: 0; }

.section_spacing_small {
  margin-top: 30px;
  margin-bottom: 30px; }

.section_spacing_large {
  margin-top: 45px;
  margin-bottom: 45px; }

.section_indent_small {
  padding-top: 20px;
  padding-bottom: 20px; }

.section_indent_medium {
  padding-top: 30px;
  padding-bottom: 30px; }

.section_indent_large {
  padding-top: 45px;
  padding-bottom: 45px; }

.section_bg_gray {
  background: #ededf1; }

.section_bg_lightgray {
  background: #f9f9fa; }

@media (min-width: 720px) {
  .section {
    margin: 60px 0; }
  .section_spacing_none {
    margin-top: 10px;
    margin-bottom: 10px; }
  .section_spacing_small {
    margin-top: 30px;
    margin-bottom: 30px; }
  .section_spacing_large {
    margin-top: 90px;
    margin-bottom: 90px; }
  .section_indent_small {
    padding-top: 20px;
    padding-bottom: 20px; }
  .section_indent_medium {
    padding-top: 45px;
    padding-bottom: 45px; }
  .section_indent_large {
    padding-top: 60px;
    padding-bottom: 60px; } }

@media (min-width: 1080px) {
  .section {
    margin: 90px 0; }
  .section_spacing_none {
    margin-top: 10px;
    margin-bottom: 10px; }
  .section_spacing_small {
    margin-top: 30px;
    margin-bottom: 30px; }
  .section_spacing_large {
    margin-top: 120px;
    margin-bottom: 120px; }
  .section_indent_small {
    padding-top: 30px;
    padding-bottom: 30px; }
  .section_indent_medium {
    padding-top: 60px;
    padding-bottom: 60px; }
  .section_indent_large {
    padding-top: 90px;
    padding-bottom: 90px; } }

.create-canvas-card {
  color: #3e3e4b;
  text-decoration: none;
  max-width: 320px;
  min-height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  padding: 40px;
  background: #ededf1;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;
  will-change: transform, box-shadow; }

.create-canvas-card__media {
  margin-bottom: 40px; }
  .create-canvas-card__media img,
  .create-canvas-card__media svg {
    max-width: 100%;
    vertical-align: top; }
  .create-canvas-card__media svg {
    opacity: 0.3; }

.create-canvas-card__desc {
  flex-grow: 1;
  margin-bottom: 40px; }

.create-canvas-card__cta {
  font-weight: 700;
  font-size: 1.125rem; }
  .create-canvas-card__cta .icon {
    margin-left: 12px;
    width: 18px;
    height: 12px;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease; }

.create-canvas-card:hover,
.create-canvas-card:focus {
  outline: none;
  -webkit-transform: translate3d(0, -10px, 0);
          transform: translate3d(0, -10px, 0);
  box-shadow: 0px 8px 10px -5px rgba(106, 106, 128, 0.23), 0px 16px 24px 2px rgba(106, 106, 128, 0.14), 0px 6px 30px 5px rgba(106, 106, 128, 0.12); }
  .create-canvas-card:hover .create-canvas-card__cta .icon,
  .create-canvas-card:focus .create-canvas-card__cta .icon {
    -webkit-transform: translateX(15px);
            transform: translateX(15px); }

.create-canvas-card:active {
  box-shadow: 0px 5px 5px -3px rgba(106, 106, 128, 0.23), 0px 8px 10px 1px rgba(106, 106, 128, 0.14), 0px 3px 14px 2px rgba(106, 106, 128, 0.12);
  -webkit-transform: translate3d(0, -5px, 0);
          transform: translate3d(0, -5px, 0); }

.create-canvas-card_type_business {
  background-color: #ececfd; }
  .create-canvas-card_type_business:hover, .create-canvas-card_type_business:focus {
    box-shadow: 0px 8px 10px -5px rgba(59, 59, 235, 0.23), 0px 16px 24px 2px rgba(59, 59, 235, 0.14), 0px 6px 30px 5px rgba(59, 59, 235, 0.12); }
  .create-canvas-card_type_business:active {
    box-shadow: 0px 5px 5px -3px rgba(59, 59, 235, 0.23), 0px 8px 10px 1px rgba(59, 59, 235, 0.14), 0px 3px 14px 2px rgba(59, 59, 235, 0.12); }

.create-canvas-card_type_value {
  background-color: #fcf4eb; }
  .create-canvas-card_type_value:hover, .create-canvas-card_type_value:focus {
    box-shadow: 0px 8px 10px -5px rgba(235, 147, 59, 0.23), 0px 16px 24px 2px rgba(235, 147, 59, 0.14), 0px 6px 30px 5px rgba(235, 147, 59, 0.12); }
  .create-canvas-card_type_value:active {
    box-shadow: 0px 5px 5px -3px rgba(235, 147, 59, 0.23), 0px 8px 10px 1px rgba(235, 147, 59, 0.14), 0px 3px 14px 2px rgba(235, 147, 59, 0.12); }

.create-canvas-card_type_lean {
  background-color: #f4fceb; }
  .create-canvas-card_type_lean:hover, .create-canvas-card_type_lean:focus {
    box-shadow: 0px 8px 10px -5px rgba(147, 235, 59, 0.23), 0px 16px 24px 2px rgba(147, 235, 59, 0.14), 0px 6px 30px 5px rgba(147, 235, 59, 0.12); }
  .create-canvas-card_type_lean:active {
    box-shadow: 0px 5px 5px -3px rgba(147, 235, 59, 0.23), 0px 8px 10px 1px rgba(147, 235, 59, 0.14), 0px 3px 14px 2px rgba(147, 235, 59, 0.12); }

.create-canvas-card_size_small {
  padding: 20px; }
  .create-canvas-card_size_small .create-canvas-card__media {
    margin-bottom: 20px; }
  .create-canvas-card_size_small .create-canvas-card__title {
    font-size: 1.125rem;
    min-height: 2.25em;
    margin-bottom: 0; }

@media (min-width: 720px) {
  .create-canvas-card_size_normal {
    padding: 30px; }
  .create-canvas-card__media {
    margin-bottom: 30px; }
  .create-canvas-card__title {
    font-size: 1.125rem;
    min-height: 2.25em; }
  .create-canvas-card__desc {
    font-size: 0.875rem; }
  .create-canvas-card__cta {
    font-size: 1rem; } }

@media (min-width: 1080px) {
  .create-canvas-card_size_normal {
    padding: 40px; }
  .create-canvas-card__media {
    margin-bottom: 40px; }
  .create-canvas-card__title {
    font-size: 1.5rem; }
  .create-canvas-card__desc {
    font-size: 1rem; }
  .create-canvas-card__cta {
    font-size: 1.125rem; } }

.create-canvas-section {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center; }

.create-canvas-section__col {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 40px; }

@media (min-width: 720px) {
  .create-canvas-section {
    margin: 0 -10px; }
  .create-canvas-section__col {
    max-width: 360px;
    width: 33%;
    margin-bottom: 0;
    padding: 0 10px; }
  .modal .create-canvas-section {
    margin-top: 60px;
    margin-bottom: 40px; } }

@media (min-width: 1080px) {
  .create-canvas-section {
    margin: 0 -20px; }
  .create-canvas-section__col {
    width: 33%;
    padding: 0 20px; } }

.brands {
  text-align: center; }

.brands__item {
  display: inline-block;
  vertical-align: middle;
  width: 30%;
  padding: 0 5px;
  margin-bottom: 10px; }

@media (min-width: 720px) {
  .brands__item {
    width: 20%; } }

@media (min-width: 1080px) {
  .brands__item {
    width: 10%;
    margin-bottom: 0; } }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes zoom-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoom-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.015);
            transform: scale(1.015); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.015);
            transform: scale(1.015); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes loading {
  from {
    left: -30%;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

@keyframes loading {
  from {
    left: -30%;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

.canvas-card {
  position: relative;
  display: block;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto; }
  .canvas-card:hover .canvas-card__media {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
    box-shadow: 0px 7px 8px -4px rgba(59, 59, 235, 0.23), 0px 12px 17px 2px rgba(59, 59, 235, 0.14), 0px 5px 22px 4px rgba(59, 59, 235, 0.12); }
  .items-list_load .canvas-card {
    -webkit-animation: 800ms fade-in;
            animation: 800ms fade-in; }

.canvas-card__link {
  color: #15151a;
  text-decoration: none;
  position: relative;
  display: block;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

.canvas-card__media {
  background: #ededf1;
  padding: 30px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;
  will-change: transform, box-shadow; }
  .canvas-card__media img,
  .canvas-card__media svg {
    max-width: 100%;
    vertical-align: top; }
  .canvas-card__media svg {
    opacity: 0.3; }

.canvas-card__body {
  margin: 1rem 35px 0 0; }

.canvas-card__title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.125;
  overflow: hidden;
  max-height: 3.375em;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

.canvas-card__title_no {
  color: #8686a2;
  font-weight: normal; }

.canvas-card__type {
  font-size: 1rem;
  line-height: 1.5;
  color: #b1b1c3;
  margin: 0; }

.canvas-card_type_business .canvas-card__media {
  background-color: #ececfd; }

.canvas-card_type_business:hover .canvas-card__media {
  box-shadow: 0px 7px 8px -4px rgba(59, 59, 235, 0.23), 0px 12px 17px 2px rgba(59, 59, 235, 0.14), 0px 5px 22px 4px rgba(59, 59, 235, 0.12); }

.canvas-card_type_business:hover .canvas-card__delete {
  box-shadow: 0px 3px 1px -2px rgba(59, 59, 235, 0.23), 0px 2px 2px 0px rgba(59, 59, 235, 0.14), 0px 1px 5px 0px rgba(59, 59, 235, 0.12); }

.canvas-card_type_value .canvas-card__media,
.canvas-card_type_value-osterwalder .canvas-card__media {
  background-color: #fcf4eb; }

.canvas-card_type_value:hover .canvas-card__media,
.canvas-card_type_value-osterwalder:hover .canvas-card__media {
  box-shadow: 0px 7px 8px -4px rgba(186, 116, 46, 0.23), 0px 12px 17px 2px rgba(186, 116, 46, 0.14), 0px 5px 22px 4px rgba(186, 116, 46, 0.12); }

.canvas-card_type_value:hover .canvas-card__delete,
.canvas-card_type_value-osterwalder:hover .canvas-card__delete {
  box-shadow: 0px 3px 1px -2px rgba(186, 116, 46, 0.23), 0px 2px 2px 0px rgba(186, 116, 46, 0.14), 0px 1px 5px 0px rgba(186, 116, 46, 0.12); }

.canvas-card_type_lean .canvas-card__media {
  background-color: #f4fceb; }

.canvas-card_type_lean:hover .canvas-card__media {
  box-shadow: 0px 7px 8px -4px rgba(117, 187, 46, 0.23), 0px 12px 17px 2px rgba(117, 187, 46, 0.14), 0px 5px 22px 4px rgba(117, 187, 46, 0.12); }

.canvas-card_type_lean:hover .canvas-card__delete {
  box-shadow: 0px 3px 1px -2px rgba(117, 187, 46, 0.23), 0px 2px 2px 0px rgba(117, 187, 46, 0.14), 0px 1px 5px 0px rgba(117, 187, 46, 0.12); }

.canvas-card_mock {
  -webkit-animation: none;
          animation: none; }
  .canvas-card_mock:hover .canvas-card__media {
    -webkit-transform: none;
            transform: none;
    box-shadow: none; }
  .canvas-card_mock .canvas-card__media {
    padding: 80% 0 0;
    background: #f9f9fa;
    -webkit-animation: 3s canvas-card-loading infinite;
            animation: 3s canvas-card-loading infinite; }
  .canvas-card_mock .canvas-card__title:before,
  .canvas-card_mock .canvas-card__type:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    background-color: #f9f9fa;
    -webkit-animation: 3s canvas-card-loading infinite;
            animation: 3s canvas-card-loading infinite; }
  .canvas-card_mock .canvas-card__title:before {
    height: 0.8rem;
    width: 85%; }
  .canvas-card_mock .canvas-card__type:before {
    height: 0.66rem;
    width: 50%; }

@-webkit-keyframes canvas-card-loading {
  0% {
    background-color: #f9f9fa; }
  50% {
    background-color: #e0e0e7; }
  100% {
    background-color: #f9f9fa; } }

@keyframes canvas-card-loading {
  0% {
    background-color: #f9f9fa; }
  50% {
    background-color: #e0e0e7; }
  100% {
    background-color: #f9f9fa; } }

.canvas-card__delete {
  color: #9d9db4;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  right: -.75rem;
  top: -1.375rem;
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0;
  background: #fff;
  border-radius: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  will-change: opacity, box-shadow;
  -webkit-transition: box-shadow 150ms ease, opacity 150ms ease;
  transition: box-shadow 150ms ease, opacity 150ms ease;
  box-shadow: 0px 3px 1px -2px rgba(134, 134, 162, 0.23), 0px 2px 2px 0px rgba(134, 134, 162, 0.14), 0px 1px 5px 0px rgba(134, 134, 162, 0.12); }

.canvas-card:hover .canvas-card__delete {
  opacity: 1; }

.canvas-card__delete:active {
  color: #ba2e2e; }

.canvas-card__delete:focus {
  outline: none; }

.canvas-card__confirming-remove {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.85); }

.canvas-card_removing {
  opacity: .2; }

.canvas-card_removing:hover .canvas-card__media,
.canvas-card_removing-confirm:hover .canvas-card__media {
  -webkit-transition: none;
  transition: none;
  -webkit-transform: none;
          transform: none;
  box-shadow: none; }

@media (min-width: 720px) {
  .canvas-card__title {
    font-size: 1.125rem; } }

.items-list {
  padding: 0 15px; }

.items-list__list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0; }

.items-list__item {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin: 0 0 30px; }

@media (min-width: 720px) {
  .items-list {
    padding: 0 20px; }
  .items-list__list {
    margin: 0 -20px; }
  .items-list__item {
    width: 33%;
    margin: 0 0 40px;
    padding: 0 20px; }
  .items-list_no-wrap {
    white-space: nowrap; }
    .items-list_no-wrap .items-list__list {
      overflow: hidden;
      padding-top: 25px; }
    .items-list_no-wrap .items-list__item {
      white-space: normal; } }

@media (min-width: 1080px) {
  .items-list {
    padding: 0; }
  .items-list__list {
    margin: 0 -30px; }
  .items-list__item {
    width: 25%;
    margin: 0 0 60px;
    padding: 0 30px; }
  .items-list_no-wrap .items-list__list {
    overflow: visible;
    padding: 0; } }

.benefit {
  text-align: center;
  margin-bottom: 60px; }
  .benefit h1,
  .benefit h2 {
    margin-bottom: 0.5em; }

.benefit__img {
  margin-bottom: 1em; }

.benefit__content {
  padding: 0 20px; }

.benefit__description {
  font-size: 1.125rem; }

@media (min-width: 720px) {
  .benefit__content {
    padding: 0; }
  .benefit__img {
    margin-bottom: 0; } }

@media (min-width: 1080px) {
  .benefit {
    text-align: left;
    margin-bottom: 0; }
  .benefit_reverse .grid__row {
    flex-direction: row-reverse; } }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes zoom-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoom-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.015);
            transform: scale(1.015); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.015);
            transform: scale(1.015); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes loading {
  from {
    left: -30%;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

@keyframes loading {
  from {
    left: -30%;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

.tabs__tabs-bar {
  display: none;
  justify-content: center;
  margin-bottom: 30px; }

.tabs__tab {
  color: #8686a2;
  font-weight: bold;
  font-size: 1rem;
  position: relative;
  padding: 20px 30px;
  background: transparent;
  cursor: pointer;
  -webkit-transition: 200ms color;
  transition: 200ms color;
  will-change: color; }
  .tabs__tab:hover {
    color: #3e3e4b; }
    .tabs__tab:hover .tabs__tab-icon {
      fill: #3e3e4b; }
  .tabs__tab span {
    position: relative; }

.tabs__tab_active {
  color: #3e3e4b; }
  .tabs__tab_active:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 5px 5px -3px rgba(106, 106, 128, 0.17), 0px 8px 10px 1px rgba(106, 106, 128, 0.08), 0px 3px 14px 2px rgba(106, 106, 128, 0.06);
    -webkit-animation: 280ms bounce;
            animation: 280ms bounce; }
  .tabs__tab_active .tabs__tab-icon {
    fill: #3e3e4b; }

.tabs__tab_disabled {
  color: #c2c2d0;
  cursor: default; }
  .tabs__tab_disabled .tabs__tab-icon {
    fill: #c2c2d0; }

.tabs__tab_icon {
  padding-left: 54px; }

.tabs__tab-icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: -34px;
  margin-right: 10px;
  vertical-align: text-bottom;
  fill: #8686a2;
  -webkit-transition: 200ms fill easy;
  transition: 200ms fill easy;
  will-change: fill; }

.tabs__pane {
  margin: 40px 0; }

.tabs_align_left .tabs__tabs-bar,
.tabs_align_right .tabs__tabs-bar {
  display: none; }

@media (min-width: 720px) {
  .tabs__tabs-bar {
    display: flex; }
  .tabs__pane {
    display: none;
    margin: 0; }
  .tabs__pane_active {
    display: block;
    -webkit-animation: 480ms fade-in;
            animation: 480ms fade-in; }
  .tabs_align_left,
  .tabs_align_right {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start; }
    .tabs_align_left .tabs__content,
    .tabs_align_right .tabs__content {
      flex-grow: 1; }
    .tabs_align_left .tabs__tabs-bar,
    .tabs_align_right .tabs__tabs-bar {
      display: block;
      flex-direction: column;
      flex-shrink: 0;
      width: 17.5rem;
      margin-right: 40px;
      margin-bottom: 0; }
    .tabs_align_left .tabs__tab,
    .tabs_align_right .tabs__tab {
      margin-bottom: 0.75rem; }
  .tabs_align_right {
    flex-direction: row-reverse; }
    .tabs_align_right .tabs__tabs-bar {
      margin-left: 40px;
      margin-right: 0; } }

@media (min-width: 1080px) {
  .tabs_align_left .tabs__tabs-bar {
    margin-right: 60px; }
  .tabs_align_right .tabs__tabs-bar {
    margin-left: 60px; } }

.features-tabs__title {
  color: #3e3e4b;
  margin-bottom: 1em; }
  .features-tabs__title .icon {
    width: 24px;
    height: 24px;
    margin-right: 0.8em;
    vertical-align: text-bottom; }

.features-tabs__section {
  margin: 40px 0;
  text-align: center; }

@media (min-width: 720px) {
  .features-tabs__section {
    margin: 0; } }

.hall-of-fame a {
  color: #15151a; }
  .hall-of-fame a:hover {
    color: #3b3beb; }

.user-home__latest {
  margin: 60px 0 90px; }

.user-home__latest-more {
  max-width: 480px;
  margin: 0 auto; }

.user-home__latest-title {
  text-align: center;
  font-size: 1.75rem; }

@media (min-width: 1080px) {
  .user-home__latest-title {
    text-align: left;
    font-size: 1.5rem; } }

.page-title {
  margin: 60px auto 60px;
  text-align: center; }

.page-title__title {
  margin-bottom: .25em; }

.page-title__subtitle {
  font-size: 1.5rem;
  margin: 0; }

.header-button {
  color: #15151a;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.125;
  text-decoration: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0; }
  .header-button:active {
    color: #3b3beb;
    background-color: #f9f9fa; }
    .header-button:active .icon {
      fill: #3b3beb; }
  .header-button:hover .icon_arrow-back {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }

.header-button__inner {
  display: flex; }

.header-button__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center; }
  .header-button__icon .icon {
    max-width: 20px;
    max-height: 20px; }
  .header-button__icon .icon_dashboard {
    vertical-align: middle;
    width: 18px;
    height: 18px; }
  .header-button__icon .icon_arrow-back {
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease; }

.header-button__label {
  display: none;
  margin-left: -0.5em;
  padding: 20px 20px 20px 0; }

.header-button_align_right .header-button__inner {
  flex-direction: row-reverse; }

.header-button_align_right .header-button__label {
  margin-left: 0;
  margin-right: -0.5em;
  padding-left: 20px;
  padding-right: 0; }

@media (min-width: 480px) {
  .header-button__label {
    display: block; } }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes zoom-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoom-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.015);
            transform: scale(1.015); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.015);
            transform: scale(1.015); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes loading {
  from {
    left: -30%;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

@keyframes loading {
  from {
    left: -30%;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

.dropdown {
  position: relative; }

.dropdown__menu {
  color: #8686a2;
  font-size: 1.125rem;
  line-height: 1.125;
  position: absolute;
  z-index: 1000;
  right: 10px;
  top: 100%;
  min-width: 20rem;
  margin-top: 1px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(177, 177, 195, 0.23), 0px 6px 10px 0px rgba(177, 177, 195, 0.14), 0px 1px 18px 0px rgba(177, 177, 195, 0.12);
  -webkit-animation: 200ms fade-in;
          animation: 200ms fade-in; }

.dropdown__list {
  margin: 0;
  padding: 0;
  list-style: none; }

.dropdown__item {
  margin: 0;
  padding: 0; }

.dropdown__item + .dropdown__item {
  border-top: 1px solid #ededf1; }

.dropdown__item_danger {
  color: #ba2e2e; }

.dropdown__button {
  display: block;
  color: #8686a2;
  text-decoration: none;
  padding: 20px 30px;
  cursor: pointer; }
  .dropdown__button:hover {
    color: #3e3e4b;
    background-color: #f9f9fa; }

.dropdown__before {
  padding: 20px 30px;
  background: #f9f9fa;
  border-bottom: 1px solid #ededf1; }

.dropdown__after {
  padding: 20px 30px;
  border-top: 1px solid #ededf1; }

.mini-profile__name,
.mini-profile__email {
  font-size: 1.125rem;
  line-height: 1.25;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 16rem;
  margin: 0; }

.header {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-content: stretch;
  height: 60px; }

.header__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 60px;
  line-height: 30px; }
  .header__logo a {
    padding: 15px 20px; }
    .header__logo a:active {
      background: #f9f9fa; }
  .header__logo svg {
    width: 40px;
    height: 30px;
    vertical-align: top; }

.header__left,
.header__right {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  width: calc(50% - 40px); }

.header__left-inner,
.header__right-inner {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center; }

.header__right-inner {
  flex-direction: row-reverse; }

.header_fixed {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 1px 0 #ededf1; }
  .header_fixed .header-button_dashboard .header-button__label,
  .header_fixed .header-button_user .header-button__label {
    display: none; }
  .header_fixed .header-button_dashboard {
    border-right: 1px solid #ededf1; }
  .header_fixed .header-button_share,
  .header_fixed .header-button_print,
  .header_fixed .header-button_user {
    border-left: 1px solid #ededf1; }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes zoom-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoom-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.015);
            transform: scale(1.015); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.015);
            transform: scale(1.015); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes loading {
  from {
    left: -30%;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

@keyframes loading {
  from {
    left: -30%;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

.modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-animation: 200ms fade-in;
          animation: 200ms fade-in; }

.modal__backdrop {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: rgba(249, 249, 250, 0.8); }

.modal__container {
  position: relative;
  margin-top: 10px;
  flex: 0 0 auto;
  width: 100%;
  padding: 30px 40px;
  background: #fff;
  box-shadow: 0px 8px 10px -5px rgba(134, 134, 162, 0.23), 0px 16px 24px 2px rgba(134, 134, 162, 0.14), 0px 6px 30px 5px rgba(134, 134, 162, 0.12);
  -webkit-animation: 200ms zoom-in;
          animation: 200ms zoom-in; }

.modal__container_size_tiny,
.modal__container_size_small,
.modal__container_size_large {
  width: 100%; }

.modal__title {
  font-size: 1.5rem;
  margin: 0 0 45px; }

.modal__footer {
  text-align: center; }

.modal__close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none; }
  .modal__close .icon {
    width: 22px;
    height: 22px;
    fill: #9d9db4;
    opacity: .5;
    -webkit-transition: opacity 300ms ease;
    transition: opacity 300ms ease; }
  .modal__close:hover .icon {
    opacity: 1; }
  .modal__close:active svg {
    fill: #3b3beb; }

.modal-scroll-lock {
  overflow: hidden; }

@media (min-width: 720px) {
  .modal__container {
    margin-top: 100px;
    width: 600px;
    padding: 30px 40px; }
  .modal__container_size_tiny {
    width: 400px; }
  .modal__container_size_small {
    width: 450px; }
  .modal__container_size_large {
    width: 800px; }
  .modal__close {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 30px;
    height: 30px; } }

@media (min-width: 1080px) {
  .modal-scroll-lock {
    overflow: hidden;
    padding-right: 15px; }
    .modal-scroll-lock .header_fixed {
      padding-right: 15px; } }

.toolbar-new-canvas {
  padding: 0; }
  .header_fixed .toolbar-new-canvas {
    padding: 0 10px; }

.toolbar-new-canvas__mobile {
  font-size: 1.25rem;
  padding-left: 0;
  padding-right: 0;
  width: 40px;
  height: 40px; }

.toolbar-new-canvas__tablet {
  display: none; }

@media (min-width: 720px) {
  .toolbar-new-canvas,
  .header_fixed .toolbar-new-canvas {
    padding: 0 20px; }
  .toolbar-new-canvas__mobile {
    display: none; }
  .toolbar-new-canvas__tablet {
    display: inline-block; } }

@charset "UTF-8";
.canvas-title {
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  margin: 10px 0 -20px;
  -webkit-transition: background-color 250ms ease;
  transition: background-color 250ms ease;
  will-change: background-color; }
  .canvas-title:after {
    content: '⮐';
    color: #8686a2;
    font-size: 1rem;
    font-weight: normal;
    line-height: 60px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    opacity: 0;
    -webkit-transition: opacity 100ms ease;
    transition: opacity 100ms ease; }

.canvas-title:hover,
.canvas-title_focused {
  outline: none; }

.canvas-title__input {
  color: #15151a;
  display: block;
  width: 100%;
  overflow: hidden;
  border: 0;
  padding: 1.125rem 0;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.334;
  white-space: nowrap;
  background: transparent; }
  .canvas-title__input:focus {
    outline: none; }

.canvas-title__type {
  color: #8686a2;
  font-size: .75rem;
  line-height: 1.25rem;
  position: absolute;
  left: 20px;
  top: 4px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.canvas-title_focused:before {
  opacity: 0; }

.canvas-title_focused:after {
  opacity: 1; }

.canvas-title_focused .canvas-title__input {
  padding-right: 50px; }

.canvas-title_empty,
.canvas-title_empty .canvas-title__input {
  color: #c2c2d0; }

.canvas-title_read-only,
.canvas-title_read-only:hover {
  cursor: default; }

.header .canvas-title {
  background: #fff;
  width: calc(100% - 60px);
  margin: 0; }
  .header .canvas-title:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 100%;
    background: -webkit-gradient(linear, right top, left top, from(#fff), color-stop(50%, #fff), to(rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(right, #fff, #fff 50%, rgba(255, 255, 255, 0));
    background: linear-gradient(to left, #fff, #fff 50%, rgba(255, 255, 255, 0));
    opacity: 1; }

.header .canvas-title:hover,
.header .canvas-title_focused {
  background: #f9f9fa; }

.header .canvas-title_read-only,
.header .canvas-title_read-only:hover {
  background: #fff; }

.header .canvas-title__input {
  padding-left: 20px;
  padding-right: 20px; }

.header .canvas-title {
  display: none; }

.layout__container .canvas-title {
  display: block; }
  .layout__container .canvas-title:after {
    display: none; }
  .layout__container .canvas-title .canvas-title__input {
    font-size: 1.5rem; }

@media (min-width: 1080px) {
  .header .canvas-title {
    display: block; }
  .layout__container .canvas-title {
    display: none; } }

@charset "UTF-8";
.entry {
  color: #15151a;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.45;
  position: relative;
  margin: 0 -0.75em 0.125em;
  padding: .25em 2em 0.25em 0.75em;
  border-radius: 4px;
  -webkit-transition: background-color 150ms ease;
  transition: background-color 150ms ease; }
  .entry span {
    color: #15151a;
    -webkit-transition: background-color 100ms;
    transition: background-color 100ms; }
  .entry span {
    background: #3b3beb;
    box-shadow: -0.5em 0 0 #3b3beb, 0.5em 0 0 #3b3beb; }
  .entry:focus span {
    background: none;
    box-shadow: none; }
  .canvas-column:nth-child(1n) .entry span {
    background: #ececfd;
    box-shadow: -0.5em 0 0 #ececfd, 0.5em 0 0 #ececfd; }
  .canvas-column:nth-child(1n) .entry:focus span {
    background: none;
    box-shadow: none; }
  .canvas-column:nth-child(2n) .entry span {
    background: #f4ebfc;
    box-shadow: -0.5em 0 0 #f4ebfc, 0.5em 0 0 #f4ebfc; }
  .canvas-column:nth-child(2n) .entry:focus span {
    background: none;
    box-shadow: none; }
  .canvas-column:nth-child(3n) .entry span {
    background: #fdebfd;
    box-shadow: -0.5em 0 0 #fdebfd, 0.5em 0 0 #fdebfd; }
  .canvas-column:nth-child(3n) .entry:focus span {
    background: none;
    box-shadow: none; }
  .canvas-column:nth-child(4n) .entry span {
    background: #fdebf4;
    box-shadow: -0.5em 0 0 #fdebf4, 0.5em 0 0 #fdebf4; }
  .canvas-column:nth-child(4n) .entry:focus span {
    background: none;
    box-shadow: none; }
  .canvas-column:nth-child(5n) .entry span {
    background: #fdebeb;
    box-shadow: -0.5em 0 0 #fdebeb, 0.5em 0 0 #fdebeb; }
  .canvas-column:nth-child(5n) .entry:focus span {
    background: none;
    box-shadow: none; }
  .canvas-column:nth-child(6n) .entry span {
    background: #fcf4eb;
    box-shadow: -0.5em 0 0 #fcf4eb, 0.5em 0 0 #fcf4eb; }
  .canvas-column:nth-child(6n) .entry:focus span {
    background: none;
    box-shadow: none; }
  .canvas-column:nth-child(7n) .entry span {
    background: #fcfce4;
    box-shadow: -0.5em 0 0 #fcfce4, 0.5em 0 0 #fcfce4; }
  .canvas-column:nth-child(7n) .entry:focus span {
    background: none;
    box-shadow: none; }
  .canvas-column:nth-child(8n) .entry span {
    background: #f4fceb;
    box-shadow: -0.5em 0 0 #f4fceb, 0.5em 0 0 #f4fceb; }
  .canvas-column:nth-child(8n) .entry:focus span {
    background: none;
    box-shadow: none; }
  .canvas-column:nth-child(9n) .entry span {
    background: #e9fce9;
    box-shadow: -0.5em 0 0 #e9fce9, 0.5em 0 0 #e9fce9; }
  .canvas-column:nth-child(9n) .entry:focus span {
    background: none;
    box-shadow: none; }
  .canvas-column:nth-child(10n) .entry span {
    background: #e8fcf2;
    box-shadow: -0.5em 0 0 #e8fcf2, 0.5em 0 0 #e8fcf2; }
  .canvas-column:nth-child(10n) .entry:focus span {
    background: none;
    box-shadow: none; }
  .canvas-column:nth-child(11n) .entry span {
    background: #e8fcfc;
    box-shadow: -0.5em 0 0 #e8fcfc, 0.5em 0 0 #e8fcfc; }
  .canvas-column:nth-child(11n) .entry:focus span {
    background: none;
    box-shadow: none; }
  .canvas-column:nth-child(12n) .entry span {
    background: #e8f2fc;
    box-shadow: -0.5em 0 0 #e8f2fc, 0.5em 0 0 #e8f2fc; }
  .canvas-column:nth-child(12n) .entry:focus span {
    background: none;
    box-shadow: none; }

.entry:hover {
  outline: none;
  background: #ededf1; }

.entry:focus {
  outline: none;
  background: #e0e0e7; }

.entry:before {
  content: '⮐';
  color: #8686a2;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 2;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -0.75rem;
  opacity: 0;
  -webkit-transition: opacity 150ms ease;
  transition: opacity 150ms ease; }

.entry:focus:before {
  opacity: 1; }

.entry_disabled, .entry_disabled:hover {
  background: none; }

.canvas-column {
  min-height: 29vh;
  padding: 1rem 20px 2rem;
  background: #fff;
  border-color: #e0e0e7;
  border-style: solid;
  border-width: 0 1px 0 0;
  -webkit-transition: background-color 250ms ease;
  transition: background-color 250ms ease;
  cursor: text; }

.canvas-column:hover {
  background: #f9f9fa; }

.canvas-column__title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.15;
  margin: 0 0 .25em; }

.canvas-column__hints {
  color: #8686a2;
  font-size: 0.75rem;
  line-height: 1.25;
  margin-bottom: 1em; }

.canvas-column__hints p {
  margin: 0 0 .25em; }

.canvas-column__entries {
  min-height: 5rem; }

.canvas-column_read-only,
.canvas-column_read-only:hover {
  cursor: inherit;
  background: #fff; }

.canvas-table_type_business .canvas-column_partners {
  grid-column: 1;
  grid-row: 1/3; }

.canvas-table_type_business .canvas-column_activities {
  grid-column: 2;
  grid-row: 1; }

.canvas-table_type_business .canvas-column_resources {
  grid-column: 2;
  grid-row: 2;
  border-width: 1px 1px 0 0; }

.canvas-table_type_business .canvas-column_proposition {
  grid-column: 3;
  grid-row: 1/3; }

.canvas-table_type_business .canvas-column_relationships {
  grid-column: 4;
  grid-row: 1; }

.canvas-table_type_business .canvas-column_channels {
  grid-column: 4;
  grid-row: 2;
  border-width: 1px 1px 0 0; }

.canvas-table_type_business .canvas-column_customers {
  grid-column: 5;
  grid-row: 1/3;
  border: 0; }

.canvas-table_type_business .canvas-column_cost {
  grid-column: 1;
  grid-row: 3;
  width: 250%;
  padding-right: 25%;
  border-width: 1px 1px 0 0; }

.canvas-table_type_business .canvas-column_revenue {
  grid-column: 1;
  grid-row: 3;
  width: 250%;
  margin-left: 250%;
  padding-right: 25%;
  border-width: 1px 0 0 0; }

.canvas-table_type_value-osterwalder .canvas-column_products {
  grid-column: 1;
  grid-row: 1/3; }

.canvas-table_type_value-osterwalder .canvas-column_gain-creators {
  grid-column: 2;
  grid-row: 1; }

.canvas-table_type_value-osterwalder .canvas-column_pain-relievers {
  grid-column: 2;
  grid-row: 2;
  border-width: 1px 1px 0 0; }

.canvas-table_type_value-osterwalder .canvas-column_gains {
  grid-column: 3;
  grid-row: 1; }

.canvas-table_type_value-osterwalder .canvas-column_pains {
  grid-column: 3;
  grid-row: 2;
  border-width: 1px 1px 0 0; }

.canvas-table_type_value-osterwalder .canvas-column_jobs {
  grid-column: 4;
  grid-row: 1/3;
  border: 0; }

.canvas-table_type_value-osterwalder .canvas-column_substitution {
  grid-column: 1;
  grid-row: 3;
  width: 150%;
  padding-right: 25%;
  border-width: 1px 1px 0 0; }

.canvas-table_type_value-osterwalder .canvas-column_proposition {
  grid-column: 2;
  grid-row: 3;
  width: 100%;
  margin-left: 50%;
  border-width: 1px 1px 0 0; }

.canvas-table_type_value-osterwalder .canvas-column_segment {
  grid-column: 3;
  grid-row: 3;
  width: 150%;
  margin-left: 50%;
  padding-right: 25%;
  border-width: 1px 0 0 0; }

.canvas-table_type_value .canvas-column_benefits {
  grid-column: 1;
  grid-row: 1; }

.canvas-table_type_value .canvas-column_features {
  grid-column: 1;
  grid-row: 2;
  border-width: 1px 1px 0 0; }

.canvas-table_type_value .canvas-column_experience {
  grid-column: 2;
  grid-row: 1/3; }

.canvas-table_type_value .canvas-column_wants {
  grid-column: 3;
  grid-row: 1; }

.canvas-table_type_value .canvas-column_fears {
  grid-column: 3;
  grid-row: 2;
  border-width: 1px 1px 0 0; }

.canvas-table_type_value .canvas-column_needs {
  grid-column: 4;
  grid-row: 1/3;
  border: 0; }

.canvas-table_type_value .canvas-column_substitutes {
  grid-column: 1;
  grid-row: 3;
  width: 150%;
  padding-right: 25%;
  border-width: 1px 1px 0 0; }

.canvas-table_type_value .canvas-column_proposition {
  grid-column: 2;
  grid-row: 3;
  width: 100%;
  margin-left: 50%;
  border-width: 1px 1px 0 0; }

.canvas-table_type_value .canvas-column_segment {
  grid-column: 3;
  grid-row: 3;
  width: 150%;
  margin-left: 50%;
  padding-right: 25%;
  border-width: 1px 0 0 0; }

.canvas-table_type_lean .canvas-column_problems {
  grid-column: 1;
  grid-row: 1; }

.canvas-table_type_lean .canvas-column_alternatives {
  grid-column: 1;
  grid-row: 2; }

.canvas-table_type_lean .canvas-column_solutions {
  grid-column: 2;
  grid-row: 1; }

.canvas-table_type_lean .canvas-column_metrics {
  grid-column: 2;
  grid-row: 2;
  border-width: 1px 1px 0 0; }

.canvas-table_type_lean .canvas-column_proposition {
  grid-column: 3;
  grid-row: 1; }

.canvas-table_type_lean .canvas-column_concept {
  grid-column: 3;
  grid-row: 2; }

.canvas-table_type_lean .canvas-column_advantage {
  grid-column: 4;
  grid-row: 1; }

.canvas-table_type_lean .canvas-column_channels {
  grid-column: 4;
  grid-row: 2;
  border-width: 1px 1px 0 0; }

.canvas-table_type_lean .canvas-column_customers {
  grid-column: 5;
  grid-row: 1;
  border: 0; }

.canvas-table_type_lean .canvas-column_adopters {
  grid-column: 5;
  grid-row: 2;
  border-width: 0; }

.canvas-table_type_lean .canvas-column_cost {
  grid-column: 1;
  grid-row: 3;
  width: 250%;
  padding-right: 25%;
  border-width: 1px 1px 0 0; }

.canvas-table_type_lean .canvas-column_revenue {
  grid-column: 1;
  grid-row: 3;
  width: 250%;
  margin-left: 250%;
  padding-right: 25%;
  border-width: 1px 0 0 0; }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes zoom-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoom-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.015);
            transform: scale(1.015); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.015);
            transform: scale(1.015); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes loading {
  from {
    left: -30%;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

@keyframes loading {
  from {
    left: -30%;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

.canvas-table {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-auto-flow: column;
  min-width: 1000px;
  margin: 30px 0;
  background: #fff;
  border: 1px solid #ededf1; }

.canvas-table_loading {
  min-height: 87vh; }

.canvas-table__loader {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  height: 4px; }
  .canvas-table__loader:before {
    display: block;
    position: absolute;
    content: "";
    left: -30%;
    width: 30%;
    height: 100%;
    background-color: #3b3beb;
    -webkit-animation: loading 1s linear infinite;
            animation: loading 1s linear infinite; }

.canvas-table_type_value,
.canvas-table_type_value-osterwalder {
  grid-template-columns: repeat(4, 25%); }

.input-text {
  position: relative;
  display: block;
  padding: 0 0 3px;
  cursor: text; }
  .input-text:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #ededf1;
    border: 2px solid #ededf1;
    border-radius: 4px;
    will-change: border-color, backgrund-color;
    -webkit-transition: border-color 100ms, background-color 100ms;
    transition: border-color 100ms, background-color 100ms; }
  .input-text:hover:before {
    border-color: #e0e0e7;
    background-color: #e0e0e7; }

.input-text__input {
  font: 1.125rem/1.5 sofia-pro, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  position: relative;
  display: block;
  width: 100%;
  margin: -2px 0 0;
  padding: 1.625rem 1.5rem 0.375rem;
  background: transparent;
  border: 0; }
  .input-text__input:focus {
    outline: none; }

.input-text__label {
  color: #8686a2;
  font-size: .75rem;
  line-height: 1.25rem;
  position: absolute;
  left: 1.5rem;
  top: 0.375rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.input-text__placeholder {
  color: #8686a2;
  font: 1.125rem/2em sofia-pro, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  position: absolute;
  left: 1.5rem;
  top: 50%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.input-text__label,
.input-text__placeholder {
  will-change: opacity, transform;
  -webkit-transition: opacity 300ms 100ms, -webkit-transform 300ms 100ms;
  transition: opacity 300ms 100ms, -webkit-transform 300ms 100ms;
  transition: opacity 300ms 100ms, transform 300ms 100ms;
  transition: opacity 300ms 100ms, transform 300ms 100ms, -webkit-transform 300ms 100ms; }

.input-text__label {
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.input-text__placeholder {
  -webkit-transform: translateY(-1em);
          transform: translateY(-1em); }

.input-text__label_hidden,
.input-text__placeholder_hidden {
  opacity: 0; }

.input-text__label_hidden {
  -webkit-transform: translateY(-0.25em);
          transform: translateY(-0.25em);
  -webkit-transition-delay: unset;
          transition-delay: unset; }

.input-text__placeholder_hidden {
  -webkit-transform: translateY(-0.75em);
          transform: translateY(-0.75em);
  -webkit-transition-delay: unset;
          transition-delay: unset; }

.input-text_focused:before, .input-text_focused:hover:before {
  border-color: #3b3beb;
  background-color: #f9f9fa; }

.input-text_error:before, .input-text_error:hover:before {
  border-color: #ba2e2e; }

.input-text_disabled:before, .input-text_disabled:hover:before {
  background: #ededf1;
  border-color: #ededf1; }

.input-text_disabled .input-text__input {
  color: #6a6a80; }

.input-text_disabled .input-text__placeholder {
  color: #c2c2d0; }

.input-text_no-label .input-text__input {
  padding: 1rem 1.5rem 1rem; }

.form__row {
  position: relative; }

.form__row + .form__row {
  margin-top: 1.5rem; }

.form__row + .form__row_submit {
  margin-top: 2.5rem; }

.form__label {
  color: #3e3e4b;
  font-size: 1.125rem;
  line-height: 1.125;
  display: block;
  margin-bottom: .5em; }

.form__label a {
  color: #3e3e4b;
  text-decoration: underline; }

.form__hint,
.form__error {
  margin-top: .5em; }

.form__error {
  color: #ba2e2e;
  font-size: 1rem;
  line-height: 1.125; }

.form__hint {
  color: #9d9db4;
  font-size: 1rem;
  line-height: 1.5; }
  .form__hint a {
    color: #9d9db4; }
    .form__hint a:hover {
      color: #6a6a80; }

.form__switch {
  vertical-align: middle;
  margin-left: 1em; }

.form__input {
  font-size: 1.166667rem;
  line-height: 1.25;
  display: block;
  width: 100%;
  padding: .75em 1em;
  border: 1px solid #c8cbcc; }

.form__input:focus {
  outline: none;
  background: #f5f9fa;
  border: 1px solid #c8cbcc; }

.form__input_disabled,
.form__input_disabled:focus {
  background: #f5f9fa; }

.form__input_error,
.form__input_error:focus {
  border-color: #a82a28; }

.form_auth,
.form_account {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto; }

@media (min-width: 1080px) {
  .form_auth .form__error,
  .form_account .form__error {
    position: absolute;
    top: 50%;
    left: 100%;
    margin: 0 0 0 40px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 240px; }
  .form_account {
    margin-top: 90px; } }

.checkbox {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  padding-left: 35px; }

.checkbox .form__label {
  display: inline; }

.checkbox input {
  position: relative;
  z-index: -1;
  width: 20px;
  height: 20px;
  margin-left: -35px; }

.checkbox__box {
  position: relative;
  top: 2px;
  display: inline-block;
  vertical-align: baseline;
  margin-right: 15px;
  margin-left: -20px;
  width: 20px;
  height: 20px;
  background: #f9f9fa;
  border: 2px solid #b1b1c3;
  border-radius: 4px; }
  .checkbox__box:hover {
    background: #ededf1; }

.checkbox input:active ~ .checkbox__box {
  background-color: #e0e0e7; }

.checkbox input:checked ~ .checkbox__box {
  border-color: #3b3beb; }

.checkbox input:checked ~ .checkbox__box:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjExIiB2aWV3Qm94PSIwIDAgMTQgMTEiIHdpZHRoPSIxNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMSA0IDIuNzkyODkzMDQgMi43OTI4OTMwNG0uNzA3MTA2OTYuNzA3MTA2OTYgNi41LTcuNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjM2IzYmViIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMikiLz48L3N2Zz4=") no-repeat 50% 50%; }

.toolbar-canvas .header-button_print {
  display: none; }

@media (min-width: 720px) {
  .toolbar-canvas .header-button_print {
    display: inline-block; } }

.auth-header-text {
  color: #8686a2;
  font-size: 1rem;
  line-height: 1.25;
  padding: 20px; }
  .auth-header-text a {
    color: #3b3beb; }
  .auth-header-text span {
    display: none; }

@media (min-width: 720px) {
  .auth-header-text span {
    display: inline; } }

.divider {
  font-size: 0;
  text-align: center;
  position: relative;
  margin: 2.5rem 0;
  min-height: 1px; }
  .divider:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 1px;
    background: #e0e0e7; }

.divider__inner {
  font-size: 1.125rem;
  line-height: 1.125;
  position: relative;
  background: #fff;
  padding: 0 1em; }

.account-form-success {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 840px;
  margin: 0 auto; }
  .account-form-success > div {
    padding: 20px; }

.account-form-success__pic {
  width: 100%; }

.account-form-success__content {
  text-align: center; }

.account-form-success__title {
  font-family: orpheuspro, serif;
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 0; }

@media (min-width: 720px) {
  .account-form-success > div {
    width: 50%; }
  .account-form-success__content {
    text-align: left; }
  .account-form-success__title {
    font-size: 4rem; } }

.page-not-found {
  margin: 30px 0; }

.page-not-found__content {
  text-align: center;
  max-width: 400px;
  margin: 0 auto; }
  .page-not-found__content h1 {
    margin-bottom: 0.25em; }
  .page-not-found__content p {
    font-size: 1.125rem;
    margin-bottom: 3em; }

.page-not-found__caption {
  color: #c2c2d0;
  font-size: 1rem;
  text-align: center; }
  .page-not-found__caption a {
    color: #c2c2d0; }
    .page-not-found__caption a:hover {
      color: #3b3beb; }

@media (min-width: 720px) {
  .page-not-found__content {
    float: right;
    text-align: right; } }

@-ms-viewport {
  width: device-width; }

@viewport {
  width: device-width; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

html,
body {
  margin: 0;
  padding: 0; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

img,
video,
audio,
iframe,
object {
  max-width: 100%;
  height: auto; }

*,
*:before,
*:after {
  box-sizing: border-box; }

button {
  outline: none; }

.elevation-0 {
  box-shadow: 0px 0px 0px 0px rgba(106, 106, 128, 0.23), 0px 0px 0px 0px rgba(106, 106, 128, 0.14), 0px 0px 0px 0px rgba(106, 106, 128, 0.12); }

.elevation-1 {
  box-shadow: 0px 2px 1px -1px rgba(106, 106, 128, 0.23), 0px 1px 1px 0px rgba(106, 106, 128, 0.14), 0px 1px 3px 0px rgba(106, 106, 128, 0.12); }

.elevation-2 {
  box-shadow: 0px 3px 1px -2px rgba(106, 106, 128, 0.23), 0px 2px 2px 0px rgba(106, 106, 128, 0.14), 0px 1px 5px 0px rgba(106, 106, 128, 0.12); }

.elevation-3 {
  box-shadow: 0px 3px 3px -2px rgba(106, 106, 128, 0.23), 0px 3px 4px 0px rgba(106, 106, 128, 0.14), 0px 1px 8px 0px rgba(106, 106, 128, 0.12); }

.elevation-4 {
  box-shadow: 0px 2px 4px -1px rgba(106, 106, 128, 0.23), 0px 4px 5px 0px rgba(106, 106, 128, 0.14), 0px 1px 10px 0px rgba(106, 106, 128, 0.12); }

.elevation-5 {
  box-shadow: 0px 3px 5px -1px rgba(106, 106, 128, 0.23), 0px 5px 8px 0px rgba(106, 106, 128, 0.14), 0px 1px 14px 0px rgba(106, 106, 128, 0.12); }

.elevation-6 {
  box-shadow: 0px 3px 5px -1px rgba(106, 106, 128, 0.23), 0px 6px 10px 0px rgba(106, 106, 128, 0.14), 0px 1px 18px 0px rgba(106, 106, 128, 0.12); }

.elevation-7 {
  box-shadow: 0px 4px 5px -2px rgba(106, 106, 128, 0.23), 0px 7px 10px 1px rgba(106, 106, 128, 0.14), 0px 2px 16px 1px rgba(106, 106, 128, 0.12); }

.elevation-8 {
  box-shadow: 0px 5px 5px -3px rgba(106, 106, 128, 0.23), 0px 8px 10px 1px rgba(106, 106, 128, 0.14), 0px 3px 14px 2px rgba(106, 106, 128, 0.12); }

.elevation-9 {
  box-shadow: 0px 5px 6px -3px rgba(106, 106, 128, 0.23), 0px 9px 12px 1px rgba(106, 106, 128, 0.14), 0px 3px 16px 2px rgba(106, 106, 128, 0.12); }

.elevation-10 {
  box-shadow: 0px 6px 6px -3px rgba(106, 106, 128, 0.23), 0px 10px 14px 1px rgba(106, 106, 128, 0.14), 0px 4px 18px 3px rgba(106, 106, 128, 0.12); }

.elevation-11 {
  box-shadow: 0px 6px 7px -4px rgba(106, 106, 128, 0.23), 0px 11px 15px 1px rgba(106, 106, 128, 0.14), 0px 4px 20px 3px rgba(106, 106, 128, 0.12); }

.elevation-12 {
  box-shadow: 0px 7px 8px -4px rgba(106, 106, 128, 0.23), 0px 12px 17px 2px rgba(106, 106, 128, 0.14), 0px 5px 22px 4px rgba(106, 106, 128, 0.12); }

.elevation-13 {
  box-shadow: 0px 7px 8px -4px rgba(106, 106, 128, 0.23), 0px 13px 19px 2px rgba(106, 106, 128, 0.14), 0px 5px 24px 4px rgba(106, 106, 128, 0.12); }

.elevation-14 {
  box-shadow: 0px 7px 9px -4px rgba(106, 106, 128, 0.23), 0px 14px 21px 2px rgba(106, 106, 128, 0.14), 0px 5px 26px 4px rgba(106, 106, 128, 0.12); }

.elevation-15 {
  box-shadow: 0px 8px 9px -5px rgba(106, 106, 128, 0.23), 0px 15px 22px 2px rgba(106, 106, 128, 0.14), 0px 6px 28px 5px rgba(106, 106, 128, 0.12); }

.elevation-16 {
  box-shadow: 0px 8px 10px -5px rgba(106, 106, 128, 0.23), 0px 16px 24px 2px rgba(106, 106, 128, 0.14), 0px 6px 30px 5px rgba(106, 106, 128, 0.12); }

.elevation-17 {
  box-shadow: 0px 8px 11px -5px rgba(106, 106, 128, 0.23), 0px 17px 26px 2px rgba(106, 106, 128, 0.14), 0px 6px 32px 5px rgba(106, 106, 128, 0.12); }

.elevation-18 {
  box-shadow: 0px 9px 11px -5px rgba(106, 106, 128, 0.23), 0px 18px 28px 2px rgba(106, 106, 128, 0.14), 0px 7px 34px 6px rgba(106, 106, 128, 0.12); }

.elevation-19 {
  box-shadow: 0px 9px 12px -6px rgba(106, 106, 128, 0.23), 0px 19px 29px 2px rgba(106, 106, 128, 0.14), 0px 7px 36px 6px rgba(106, 106, 128, 0.12); }

.elevation-20 {
  box-shadow: 0px 10px 13px -6px rgba(106, 106, 128, 0.23), 0px 20px 31px 3px rgba(106, 106, 128, 0.14), 0px 8px 38px 7px rgba(106, 106, 128, 0.12); }

.elevation-21 {
  box-shadow: 0px 10px 13px -6px rgba(106, 106, 128, 0.23), 0px 21px 33px 3px rgba(106, 106, 128, 0.14), 0px 8px 40px 7px rgba(106, 106, 128, 0.12); }

.elevation-22 {
  box-shadow: 0px 10px 14px -6px rgba(106, 106, 128, 0.23), 0px 22px 35px 3px rgba(106, 106, 128, 0.14), 0px 8px 42px 7px rgba(106, 106, 128, 0.12); }

.elevation-23 {
  box-shadow: 0px 11px 14px -7px rgba(106, 106, 128, 0.23), 0px 23px 36px 3px rgba(106, 106, 128, 0.14), 0px 9px 44px 8px rgba(106, 106, 128, 0.12); }

.elevation-24 {
  box-shadow: 0px 11px 15px -7px rgba(106, 106, 128, 0.23), 0px 24px 38px 3px rgba(106, 106, 128, 0.14), 0px 9px 46px 8px rgba(106, 106, 128, 0.12); }

html {
  font-size: 93.75%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "liga", "calt", "dlig";
          font-feature-settings: "liga", "calt", "dlig"; }

body {
  color: #3e3e4b;
  font: normal 1em/1.5 sofia-pro, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: #fff;
  overflow-y: scroll; }

input::-webkit-input-placeholder {
  color: #c2c2d0; }

input::-moz-placeholder {
  color: #c2c2d0; }

input:-ms-input-placeholder {
  color: #c2c2d0; }

input::-ms-input-placeholder {
  color: #c2c2d0; }

input::placeholder {
  color: #c2c2d0; }

.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4 {
  color: #15151a;
  font-family: sofia-pro, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  line-height: 1.125;
  margin: 0 0 1em; }

.h1, h1 {
  font-size: 2rem; }

.h2, h2 {
  font-size: 1.75rem; }

.h3, h3 {
  font-size: 1.5rem; }

.h4, h4 {
  font-size: 1.125rem;
  margin-bottom: 0.3em; }

p {
  margin: 0 0 1em; }

a {
  color: #3b3beb; }

.text_align_center {
  text-align: center; }

.text_align_left {
  text-align: left; }

.text_align_right {
  text-align: right; }

.text_size_bigger {
  font-size: 1.25rem; }

.text_size_smaller {
  font-size: 0.75rem; }

@media (min-width: 720px) {
  html {
    font-size: 100%; }
  .h1, h1 {
    font-size: 3rem; }
  .h2, h2 {
    font-size: 2rem; } }

.hidden {
  display: none; }

.visible_mobile {
  display: block; }

.visible-inline_mobile {
  display: inline; }

@media (min-width: 720px) {
  .hidden {
    display: none; }
  .hidden_tablet {
    display: none; }
  .visible_tablet {
    display: block; }
  .visible-inline_tablet {
    display: inline; } }

@media (min-width: 1080px) {
  .hidden {
    display: none; }
  .hidden_desktop {
    display: none; }
  .visible_desktop {
    display: block; }
  .visible-inline_desktop {
    display: inline; } }

@media print {
  html,
  body,
  .layout__container,
  .layout__container {
    background: none !important; }
  .layout__footer {
    display: none !important; }
  .layout__container {
    padding: 0 !important;
    overflow: visible !important; }
  .header,
  .header__inner {
    position: static !important;
    height: auto !important;
    background: none !important;
    border: none !important;
    box-shadow: none !important; }
  .header__logo,
  .header .header-button,
  .toolbar-canvas,
  .toolbar-new-canvas,
  .canvas-column__hints {
    display: none !important; }
  .canvas-title__input {
    padding: 0 !important; }
  .layout__container .canvas-title {
    margin-bottom: 10px; }
  .canvas-table {
    margin: 0 !important;
    min-height: 85vh !important; }
  .canvas-table,
  .canvas-column {
    border-color: #ccc !important; }
  .canvas-column {
    min-height: 25vh !important;
    background: none !important; }
  .entry span {
    background: none !important;
    box-shadow: none !important; }
  @page {
    size: landscape;
    margin: 8mm 12mm;
    padding: 0; } }

