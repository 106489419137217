@import "../../styles/variables";
@import "../../styles/animation";

.icon_dashboard,
.icon_user,
.icon_arrow,
.icon_arrow-back,
.icon_canvas-business,
.icon_canvas-value,
.icon_canvas-lean,
.icon_cog,
.icon_share,
.icon_print {
  fill: $color-black;
}

.icon_spin {
  width: 1.5rem;
  height: 1.5rem;
  fill: $color-primary;
  animation: loadingCircle 1s infinite linear
}
