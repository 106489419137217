@import "../../styles/variables";

.divider {
  font-size: 0;
  text-align: center;
  position: relative;
  margin: 2.5rem 0;
  min-height: 1px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 1px;
    background: $color-gray8;
  }
}
.divider__inner {
  font-size: 1.125rem;
  line-height: 1.125;
  position: relative;
  background: #fff;
  padding: 0 1em;
}
