@import "../../styles/variables";

.layout {
}
.layout__container {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background: #fff;
  padding-top: 60px;
  padding-bottom: 90px;
  margin-bottom: 315px;
}
.layout__inner {
  position: relative;
  max-width: $page-width-max;
  margin: 0 auto;
  padding: 0 $page-gutter-mobile;
}
.layout__footer {
  color: $color-gray2;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 315px;
  z-index: -1;
  background: $color-black;
}


.layout_fluid {
  .layout__inner {
    max-width: none;
  }
}
.layout_no-overflow {
  .layout__container {
    overflow: auto;

    // TODO:
    .layout__inner {
      min-width: 1060px;
    }
  }
}

.layout_type_canvas {
  .layout__container {
    height: 100vh;
    overflow: auto;
  }
}

.layout_type_static-page {
  .layout__container .layout__inner {
    max-width: 800px;
    margin: 0 auto;
  }
}

// Backgrounds
// ===========
.layout_background_gradient .layout__container {
  background: linear-gradient(to top left, #d299c2 0%, #fef9d7 100%);
}
.layout_background_gradient_0 .layout__container {
  // 013 Heavy Rain
  background: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}
.layout_background_gradient_1 .layout__container {
  // 02 Night Fade
  background: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
}
.layout_background_gradient_2 .layout__container {
  background: linear-gradient(to bottom, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%, #b12a5b 100%);
}
.layout_background_gradient_3 .layout__container {
   // 025 Wilde Apple
  background: linear-gradient(to top left, #d299c2 0%, #fef9d7 100%);
}
.layout_background_gradient_4 .layout__container {
   // 003 Spring Warmth
  background: linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%);
}
.layout_background_gradient_5 .layout__container {
   // 070 Aqua Splash
  background: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
}
.layout_background_gradient_6 .layout__container {
   // 091 Eternal Constance
  background: linear-gradient(to top, #09203f 0%, #537895 100%);
}
.layout_background_gradient_7 .layout__container {
  // 012 Tempting Azure
  background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}
.layout_background_gradient_8 .layout__container {
  // 031 Blessing
  background: linear-gradient(to top left, #fddb92 0%, #d1fdff 100%);
}
.layout_background_gradient_9 .layout__container {
  // 037 Old Hat
  background: linear-gradient(to right, #e4afcb 0%, #b8cbb8 0%, #b8cbb8 0%, #e2c58b 30%, #c2ce9c 64%, #7edbdc 100%);
}

.layout_background_pic_magnus .layout__container {
  background: #e84700 url('./img/hodgepodge.png') repeat 50% 50%;
}


@media (min-width: $min-tablet) {
  .layout__container {
    margin-bottom: 300px;
  }
  .layout__footer {
    height: 300px;
  }
  .layout__inner {
    padding-left: $page-gutter-tablet;
    padding-right: $page-gutter-tablet;
  }
}
@media (min-width: $min-desktop) {
  .layout__container {
    margin-bottom: 200px;
  }
  .layout__footer {
    height: 200px;
  }
  .layout__inner {
    padding-left: $page-gutter-desktop;
    padding-right: $page-gutter-desktop;
  }
  .layout_type_canvas {
    .layout__container {
      height: auto;
      overflow: hidden;
    }
  }
}

