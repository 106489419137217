@import "../../styles/variables";

.toolbar-canvas {
  .header-button_print {
    display: none;
  }
}

@media (min-width: $min-tablet) {
  .toolbar-canvas {
    .header-button_print {
      display: inline-block;
    }
  }
}
