.page-title {
  margin: 60px auto 60px;
  text-align: center;
}
.page-title__title {
  margin-bottom: .25em;
}
.page-title__subtitle {
  font-size: 1.5rem;
  margin: 0;
}
