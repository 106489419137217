@keyframes fade-in-app {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.app {
  animation: 1400ms fade-in-app;
}
