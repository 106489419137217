@import "../../styles/variables";

.hall-of-fame {
  a {
    color: $color-black;

    &:hover {
      color: $color-primary;
    }
  }
}
