
html {
  //font-size: 87.5%;
  font-size: 93.75%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-feature-settings: "liga", "calt", "dlig";
}
body {
  color: $color-gray1;
  font: normal 1em/1.5 $font-sans;
  background: #fff;
  overflow-y: scroll;
}

input::placeholder {
  color: $color-gray6;
}

// Basic Typography
.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4 {
  color: $color-black;
  font-family: $font-sans;
  font-weight: bold;
  line-height: 1.125;
  margin: 0 0 1em;
}
.h1, h1 {
  font-size: 2rem;
}
.h2, h2 {
  font-size: 1.75rem;
}
.h3, h3 {
  font-size: 1.5rem;
}
.h4, h4 {
  font-size: 1.125rem;
  margin-bottom: 0.3em;
}
p {
  margin: 0 0 1em;
}
a {
  color: $color-primary;
}

.text_align_center {
  text-align: center;
}
.text_align_left {
  text-align: left;
}
.text_align_right {
  text-align: right;
}
.text_size_bigger {
  font-size: 1.25rem;
}
.text_size_smaller {
  font-size: 0.75rem;
}

@media (min-width: $min-tablet) {
  html {
    font-size: 100%;
  }
  .h1, h1 {
    font-size: 3rem;
  }
  .h2, h2 {
    font-size: 2rem;
  }
}
