@import "../../styles/variables";
@import "../../styles/elevate";
@import "../../styles/animation";

.modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  animation: $transition-enter fade-in;
}
.modal__backdrop {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: rgba($color-gray0, .8);
}

.modal__container {
  position: relative;
  margin-top: 10px;
  flex: 0 0 auto;
  width: 100%;
  padding: 30px 40px;
  background: #fff;
  @include elevate(16, $color-gray3);
  animation: $transition-enter zoom-in;
}
.modal__container_size_tiny,
.modal__container_size_small,
.modal__container_size_large {
  width: 100%;
}

.modal__title {
  font-size: 1.5rem;
  margin: 0 0 45px;
}
.modal__footer {
  text-align: center;
}

.modal__close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;

  .icon {
    width: 22px;
    height: 22px;
    fill: $color-gray4;
    opacity: .5;
    transition: opacity 300ms ease;
  }
  &:hover .icon {
    opacity: 1;
  }
  &:active {
    svg {
      fill: $color-primary;
    }
  }
}

// Used applying to body
.modal-scroll-lock {
  overflow: hidden;
}

@media (min-width: $min-tablet) {

  .modal__container {
    margin-top: 100px;
    width: 600px;
    padding: 30px 40px;
  }
  .modal__container_size_tiny {
    width: 400px;
  }
  .modal__container_size_small {
    width: 450px;
  }
  .modal__container_size_large {
    width: 800px;
  }
  .modal__close {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 30px;
    height: 30px;
  }
}

@media (min-width: $min-desktop) {
  .modal-scroll-lock {
    overflow: hidden;
    padding-right: 15px;

    .header_fixed {
      padding-right: 15px;
    }
  }
}
