// Checks for a valid CSS color.
@function _is-color($color) {
  @return (type-of($color) == color) or ($color == "currentColor");
}

// Mixes a color with black.
@function shade(
  $color,
  $percent
) {
  @if not _is-color($color) {
    @error "`#{$color}` is not a valid color for the `$color` argument in " +
           "the `shade` mixin.";
  } @else {
    @return mix(#000, $color, $percent);
  }
}

/// Mixes a color with white.
@function tint(
  $color,
  $percent
) {
  @if not _is-color($color) {
    @error "`#{$color}` is not a valid color for the `$color` argument in the `tint` mixin.";
  } @else {
    @return mix(#fff, $color, $percent);
  }
}
