@import "../../styles/variables";

.items-list {
  padding: 0 $gutter-mobile/2;
}
.items-list__list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}
.items-list__item {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin: 0 0 $gutter-mobile;
}

@media (min-width: $min-tablet) {
  .items-list {
    padding: 0 $gutter-tablet/2;
  }
  .items-list__list {
    margin: 0 (-$gutter-tablet/2);
  }
  .items-list__item {
    width: 33%;
    margin: 0 0 $gutter-tablet;
    padding: 0 $gutter-tablet/2;
  }
  .items-list_no-wrap {
    white-space: nowrap;

    .items-list__list {
      overflow: hidden;
      padding-top: 25px;
    }
    .items-list__item {
      white-space: normal;
    }
  }
}
@media (min-width: $min-desktop) {
  .items-list {
    padding: 0;
  }
  .items-list__list {
    margin: 0 (-$gutter-desktop/2);
  }
  .items-list__item {
    width: 25%;
    margin: 0 0 $gutter-desktop;
    padding: 0 $gutter-desktop/2;
  }
  .items-list_no-wrap {
    .items-list__list {
      overflow: visible;
      padding: 0;
    }
  }
}
