@import "../../styles/variables";

.canvas-title {
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  margin: 10px 0 -20px;
  transition: background-color 250ms ease;
  will-change: background-color;

  &:after {
    content: '⮐';
    color: $color-gray3;
    font-size: 1rem;
    font-weight: normal;
    line-height: 60px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    opacity: 0;
    transition: opacity 100ms ease;
  }
}
.canvas-title:hover,
.canvas-title_focused {
  outline: none;
}

.canvas-title__input {
  color: $color-black;
  display: block;
  width: 100%;
  overflow: hidden;
  border: 0;
  padding: 1.125rem 0;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.334;
  white-space: nowrap;
  background: transparent;

  &:focus {
    outline: none;
  }
}

.canvas-title__type {
  color: $color-gray3;
  font-size: .75rem;
  line-height: 1.25rem;
  position: absolute;
  left: 20px;
  top: 4px;
  user-select: none;
}

.canvas-title_focused {
  &:before {
    opacity: 0;
  }
  &:after {
    opacity: 1;
  }
  .canvas-title__input {
    padding-right: 50px;
  }
}
.canvas-title_empty {
  &,
  & .canvas-title__input {
    color: $color-gray6;
  }
}
.canvas-title_read-only,
.canvas-title_read-only:hover {
  cursor: default;
}


// In .HEADER
.header {
  .canvas-title {
    background: #fff;
    // All space of left header half minus size of the dashboard button
    width: calc(100% - 60px);
    margin: 0;

    &:before {
      content: '';
      position: absolute;
      top:0;
      right: 0;
      width: 60px;
      height: 100%;
      background: linear-gradient(to left, #fff, #fff 50%, rgba(#fff, 0));
      opacity: 1;
    }
  }
  .canvas-title:hover,
  .canvas-title_focused {
    background: $color-gray0;
  }
  .canvas-title_read-only,
  .canvas-title_read-only:hover {
    background: #fff;
  }
  .canvas-title__input {
    padding-left: 20px;
    padding-right: 20px;
  }
}


// TODO: Hardcode
.header .canvas-title {
  display: none;
}
.layout__container .canvas-title {
  display: block;

  &:after {
    display: none;
  }
  .canvas-title__input {
    font-size: 1.5rem;
  }
}
@media (min-width: $min-desktop) {
  .header .canvas-title {
    display: block;
  }
  .layout__container .canvas-title {
    display: none;
  }
}
