@media print {
  html,
  body,
  .layout__container,
  .layout__container {
    background: none !important;
  }
  .layout__footer {
    display: none !important;
  }

  .layout__container {
    padding: 0 !important;
    overflow: visible !important;
  }

  .header,
  .header__inner {
    position: static !important;
    height: auto !important;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  .header__logo,
  .header .header-button,
  .toolbar-canvas,
  .toolbar-new-canvas,
  .canvas-column__hints {
    display: none !important;
  }

  .canvas-title__input {
    padding: 0 !important;
  }
  .layout__container .canvas-title {
    margin-bottom: 10px;
  }

  .canvas-table {
    margin: 0 !important;
    min-height: 85vh !important;
  }
  .canvas-table,
  .canvas-column {
    border-color: #ccc !important;
  }
  .canvas-column {
    min-height: 25vh !important;
    background: none !important;
  }
  .entry span {
    background: none !important;
    box-shadow: none !important;
  }

  @page {
    size: landscape;
    margin: 8mm 12mm;
    padding: 0;
  }
}
