@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes zoom-in {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.015);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loadingCircle {
  100%{
    transform:rotate(360deg);
  }
}

@keyframes loading {
  from {
    left: -30%;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
