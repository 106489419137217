@import '../../styles/variables';

.hero {
  margin: 90px auto 120px;
  padding: 0 10px;
  text-align: center;
}
.hero__title {
  color: $color-black;
  font-family: $font-serif;
  font-size: 2.5rem;
  line-height: 1;
  margin: 0 0 0.5em;
}
.hero__subtitle {
  color: $color-gray2;
  font-family: $font-sans;
  font-size: 1.25rem;
  font-weight: normal;
}
.hero__cta {
  margin: 60px 0;

  .button {
    &:before {
      box-shadow: 0 15px 30px rgba($color-indigo, 0.3);
    }
    &:hover:before {
      box-shadow: 0 7px 10px rgba($color-indigo, 0.3);
    }
    &:active:before {
      box-shadow: 0 4px 7px rgba($color-indigo, 0.3);
    }
  }
}

@media (min-width: $min-tablet) {
  .hero {
    margin: 90px auto 120px;
    padding: 0;
  }
  .hero__title {
    font-size: 3.5rem;
  }
  .hero__subtitle {
    font-size: 1.5rem;
  }
}
@media (min-width: $min-desktop) {
  .hero {
    margin-top: 150px;
  }
  .hero__title {
    font-size: 4rem;
  }
}


