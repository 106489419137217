@import "../../styles/variables";
@import "../../styles/elevate";
@import "../../styles/animation";

.canvas-table {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-auto-flow: column;
  min-width: 1000px;
  margin: 30px 0;
  background: #fff;
  border: 1px solid $color-gray9;
  //@include elevate(16, $color-gray1, -0.05);
}
.canvas-table_loading {
  min-height: 87vh;
}
.canvas-table__loader {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;

  &:before {
    display: block;
    position: absolute;
    content: "";
    left: -30%;
    width: 30%;
    height: 100%;
    background-color: $color-primary;
    animation: loading 1s linear infinite;
  }
}

.canvas-table_type_value,
.canvas-table_type_value-osterwalder {
  grid-template-columns: repeat(4, 25%);
}
