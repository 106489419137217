@import "../../styles/variables";
@import "../../styles/animation";
@import "../../styles/elevate";

.tabs {
}
.tabs__tabs-bar {
  display: none;
  justify-content: center;
  margin-bottom: 30px
}
.tabs__tab {
  color: $color-gray3;
  font-weight: bold;
  font-size: 1rem;
  position: relative;
  padding: 20px 30px;
  background: transparent;
  cursor: pointer;
  transition: $transition-enter color;
  will-change: color;

  &:hover {
    color: $color-gray1;

    .tabs__tab-icon {
      fill: $color-gray1;
    }
  }
  span {
    position: relative;
  }
}
.tabs__tab_active {
  color: $color-gray1;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    background: #fff;
    @include elevate(8, $color-gray2, -0.06);
    animation: 280ms bounce;
  }
  .tabs__tab-icon {
    fill: $color-gray1;
  }
}
.tabs__tab_disabled {
  color: $color-gray6;
  cursor: default;

  .tabs__tab-icon {
    fill: $color-gray6;
  }
}
.tabs__tab_icon {
  padding-left: 20 + 34px;
}

.tabs__tab-icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: -34px;
  margin-right: 10px;
  vertical-align: text-bottom;
  fill: $color-gray3;
  transition: $transition-enter fill easy;
  will-change: fill;
}

.tabs__pane {
  margin: 40px 0;
}

.tabs_align_left,
.tabs_align_right {
  .tabs__tabs-bar {
    display: none;
  }
}


@media (min-width: $min-tablet) {
  .tabs__tabs-bar {
    display: flex;
  }
  .tabs__pane {
    display: none;
    margin: 0;
  }
  .tabs__pane_active {
    display: block;
    animation: 480ms fade-in;
  }

  .tabs_align_left,
  .tabs_align_right {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start;

    .tabs__content {
      flex-grow: 1;
    }
    .tabs__tabs-bar {
      display: block;
      flex-direction: column;
      flex-shrink: 0;
      width: 17.5rem;
      margin-right: $gutter-tablet;
      margin-bottom: 0;
    }
    .tabs__tab {
      margin-bottom: 0.75rem;
    }
  }
  .tabs_align_right {
    flex-direction: row-reverse;

    .tabs__tabs-bar {
      margin-left: $gutter-tablet;
      margin-right: 0;
    }
  }
}

@media (min-width: $min-desktop) {
  .tabs_align_left {
    .tabs__tabs-bar {
      margin-right: $gutter-desktop;
    }
  }
  .tabs_align_right {
    .tabs__tabs-bar {
      margin-left: $gutter-desktop;
    }
  }
}
