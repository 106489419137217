@import "../../styles/variables";

.canvas-column {
  min-height: 29vh;
  padding: 1rem 20px 2rem;
  background: #fff;
  border-color: $color-gray8;
  border-style: solid;
  border-width: 0 1px 0 0;
  transition: background-color 250ms ease;
  cursor: text;
}
.canvas-column:hover {
  background: $color-gray0;
}

.canvas-column__title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.15;
  margin: 0 0 .25em;
}
.canvas-column__hints {
  color: $color-gray3;
  font-size: 0.75rem;
  line-height: 1.25;
  margin-bottom: 1em;
}
.canvas-column__hints p {
  margin: 0 0 .25em;
}
.canvas-column__entries {
  min-height: 5rem;
}

// Read-only column doesn't highlight
.canvas-column_read-only,
.canvas-column_read-only:hover {
  cursor: inherit;
  background: #fff;
}

//
// Business Model Canvas
// ===============
.canvas-table_type_business {
  .canvas-column_partners {
    grid-column: 1;
    grid-row: 1/3;
  }
  .canvas-column_activities {
    grid-column: 2;
    grid-row: 1;
  }
  .canvas-column_resources {
    grid-column: 2;
    grid-row: 2;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_proposition {
    grid-column: 3;
    grid-row: 1/3;
  }
  .canvas-column_relationships {
    grid-column: 4;
    grid-row: 1;
  }
  .canvas-column_channels {
    grid-column: 4;
    grid-row: 2;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_customers {
    grid-column: 5;
    grid-row: 1/3;
    border: 0;
  }
  .canvas-column_cost {
    grid-column: 1;
    grid-row: 3;
    width: 250%;
    padding-right: 25%;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_revenue {
    grid-column: 1;
    grid-row: 3;
    width: 250%;
    margin-left: 250%;
    padding-right: 25%;
    border-width: 1px 0 0 0;
  }
}

//
// Value Proposition Osterwalder
// =============================
.canvas-table_type_value-osterwalder {

  .canvas-column_products {
    grid-column: 1;
    grid-row: 1/3;
  }
  .canvas-column_gain-creators {
    grid-column: 2;
    grid-row: 1;
  }
  .canvas-column_pain-relievers {
    grid-column: 2;
    grid-row: 2;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_gains {
    grid-column: 3;
    grid-row: 1;
  }
  .canvas-column_pains {
    grid-column: 3;
    grid-row: 2;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_jobs {
    grid-column: 4;
    grid-row: 1/3;
    border: 0;
  }
  .canvas-column_substitution {
    grid-column: 1;
    grid-row: 3;
    width: 150%;
    padding-right: 25%;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_proposition {
    grid-column: 2;
    grid-row: 3;
    width: 100%;
    margin-left: 50%;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_segment {
    grid-column: 3;
    grid-row: 3;
    width: 150%;
    margin-left: 50%;
    padding-right: 25%;
    border-width: 1px 0 0 0;
  }
}

//
// Value Proposition Osterwalder
// =============================
.canvas-table_type_value {
  .canvas-column_benefits {
    grid-column: 1;
    grid-row: 1;
  }
  .canvas-column_features {
    grid-column: 1;
    grid-row: 2;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_experience {
    grid-column: 2;
    grid-row: 1/3;
  }
  .canvas-column_wants {
    grid-column: 3;
    grid-row: 1;
  }
  .canvas-column_fears {
    grid-column: 3;
    grid-row: 2;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_needs {
    grid-column: 4;
    grid-row: 1/3;
    border: 0;
  }
  .canvas-column_substitutes {
    grid-column: 1;
    grid-row: 3;
    width: 150%;
    padding-right: 25%;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_proposition {
    grid-column: 2;
    grid-row: 3;
    width: 100%;
    margin-left: 50%;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_segment {
    grid-column: 3;
    grid-row: 3;
    width: 150%;
    margin-left: 50%;
    padding-right: 25%;
    border-width: 1px 0 0 0;
  }
}

//
// Lean Canvas
// ===========
.canvas-table_type_lean {
  .canvas-column_problems {
    grid-column: 1;
    grid-row: 1;
  }
  .canvas-column_alternatives {
    grid-column: 1;
    grid-row: 2;
  }
  .canvas-column_solutions {
    grid-column: 2;
    grid-row: 1;
  }
  .canvas-column_metrics {
    grid-column: 2;
    grid-row: 2;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_proposition {
    grid-column: 3;
    grid-row: 1;
  }
  .canvas-column_concept {
    grid-column: 3;
    grid-row: 2;
  }
  .canvas-column_advantage {
    grid-column: 4;
    grid-row: 1;
  }
  .canvas-column_channels {
    grid-column: 4;
    grid-row: 2;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_customers {
    grid-column: 5;
    grid-row: 1;
    border: 0;
  }
  .canvas-column_adopters {
    grid-column: 5;
    grid-row: 2;
    border-width: 0;
  }
  .canvas-column_cost {
    grid-column: 1;
    grid-row: 3;
    width: 250%;
    padding-right: 25%;
    border-width: 1px 1px 0 0;
  }
  .canvas-column_revenue {
    grid-column: 1;
    grid-row: 3;
    width: 250%;
    margin-left: 250%;
    padding-right: 25%;
    border-width: 1px 0 0 0;
  }
}
