@import "../../styles/variables";

.create-canvas-section {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}
.create-canvas-section__col {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 40px;
}
@media (min-width: $min-tablet) {
  .create-canvas-section {
    margin: 0 -10px;
  }
  .create-canvas-section__col {
    max-width: 360px;
    width: 33%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .modal .create-canvas-section {
    margin-top: 60px;
    margin-bottom: 40px;
  }
}
@media (min-width: $min-desktop) {
  .create-canvas-section {
    margin: 0 -20px;
  }
  .create-canvas-section__col {
    width: 33%;
    padding: 0 20px;
  }
}
