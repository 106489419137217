@import "../../styles/variables";
@import "../../styles/elevate";

.create-canvas-card {
  color: $color-gray1;
  text-decoration: none;
  max-width: 320px;
  min-height: 100%;

  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;

  overflow: hidden;
  padding: 40px;
  background: $color-gray9;
  border-radius: $border-radius;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  transition: box-shadow 200ms ease, transform 200ms ease;
  will-change: transform, box-shadow;
}
.create-canvas-card__media {
  margin-bottom: 40px;

  img,
  svg {
    max-width: 100%;
    vertical-align: top;
  }
  svg {
    opacity: 0.3;
  }
}
.create-canvas-card__title {
}
.create-canvas-card__desc {
  flex-grow: 1;
  margin-bottom: 40px;
}
.create-canvas-card__cta {
  font-weight: 700;
  font-size: 1.125rem;

  .icon {
    margin-left: 12px;
    width: 18px;
    height: 12px;
    transform: translateX(0);
    transition: transform 300ms ease;
  }
}

// Hover & Active
.create-canvas-card:hover,
.create-canvas-card:focus {
  outline: none;
  transform: translate3d(0, -10px, 0);
  @include elevate(16);

  .create-canvas-card__cta {
    .icon {
      transform: translateX(15px);
    }
  }
}
.create-canvas-card:active {
  @include elevate(8);
  transform: translate3d(0, -5px, 0);
}


// Theming for types
.create-canvas-card_type_business {
  background-color: $bg-indigo;

  &:hover,
  &:focus {
    @include elevate(16, $color-indigo);
  }
  &:active {
    @include elevate(8, $color-indigo);
  }
}
.create-canvas-card_type_value {
  background-color: $bg-orange;

  &:hover,
  &:focus {
    @include elevate(16, #eb933b);
  }
  &:active {
    @include elevate(8, #eb933b);
  }
}
.create-canvas-card_type_lean {
  background-color: $bg-lime;

  &:hover,
  &:focus {
    @include elevate(16, #93eb3b);
  }
  &:active {
    @include elevate(8, #93eb3b);
  }
}


// Sizing
.create-canvas-card_size_small {
  padding: 20px;

  .create-canvas-card__media {
    margin-bottom: 20px;
  }
  .create-canvas-card__title {
    font-size: 1.125rem;
    min-height: 1.125 * 2em;
    margin-bottom: 0;
  }
}


@media (min-width: $min-tablet) {
  .create-canvas-card_size_normal {
    padding: 30px;
  }
  .create-canvas-card__media {
    margin-bottom: 30px;
  }
  .create-canvas-card__title {
    font-size: 1.125rem;
    min-height: 1.125 * 2em;
  }
  .create-canvas-card__desc {
    font-size: 0.875rem;
  }
  .create-canvas-card__cta {
    font-size: 1rem;
  }
}

@media (min-width: $min-desktop) {
  .create-canvas-card_size_normal {
    padding: 40px;
  }
  .create-canvas-card__media {
    margin-bottom: 40px;
  }
  .create-canvas-card__title {
    font-size: 1.5rem;
  }
  .create-canvas-card__desc {
    font-size: 1rem;
  }
  .create-canvas-card__cta {
    font-size: 1.125rem;
  }
}
