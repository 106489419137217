@import "../../styles/variables";

.page-not-found {
  margin: 30px 0;
}
.page-not-found__content {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;

  h1 {
    margin-bottom: 0.25em;
  }
  p {
    font-size: 1.125rem;
    margin-bottom: 3em;
  }
}
.page-not-found__caption {
  color: $color-gray6;
  font-size: 1rem;
  text-align: center;

  a {
    color: $color-gray6;

    &:hover {
      color: $color-primary;
    }
  }
}

@media (min-width: $min-tablet) {
  .page-not-found__content {
    float: right;
    text-align: right;
  }
}
