@import "../../styles/variables";
@import "../../styles/elevate";
@import "../../styles/animation";

.canvas-card {
  position: relative;
  display: block;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    .canvas-card__media {
      transform: translate3d(0, -10px, 0);
      @include elevate(12, $color-indigo);
    }
  }

  .items-list_load & {
    animation: 800ms fade-in;
  }
}
.canvas-card__link {
  color: $color-black;
  text-decoration: none;
  position: relative;
  display: block;
  transition: box-shadow 150ms ease;
}
.canvas-card__media {
  background: $color-gray9;
  padding: 30px;
  border-radius: $border-radius;
  transform: translate3d(0, 0, 0);
  transition: box-shadow 200ms ease, transform 200ms ease;
  will-change: transform, box-shadow;

  img,
  svg {
    max-width: 100%;
    vertical-align: top;
  }
  svg {
    opacity: 0.3;
  }
}
.canvas-card__body {
  margin: 1rem 35px 0 0;
}
.canvas-card__title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.125;
  overflow: hidden;
  max-height: 1.125em * 3;
  margin: 0;
  // Supported by all major browser with -webkit- prefix only
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.canvas-card__title_no {
  color: $color-gray3;
  font-weight: normal;
}
.canvas-card__type {
  font-size: 1rem;
  line-height: 1.5;
  color: $color-gray5;
  margin: 0;
}


// Canvas Styling by Type
.canvas-card_type_business {
  .canvas-card__media {
    background-color: $bg-indigo;
  }
  &:hover {
    .canvas-card__media {
      @include elevate(12, $color-indigo);
    }
    .canvas-card__delete {
      @include elevate(2, $color-indigo);
    }
  }
}
.canvas-card_type_value,
.canvas-card_type_value-osterwalder {
  .canvas-card__media {
    background-color: $bg-orange;
  }
  &:hover {
    .canvas-card__media {
      @include elevate(12, $color-orange);
    }
    .canvas-card__delete {
      @include elevate(2, $color-orange);
    }
  }
}
.canvas-card_type_lean {
  .canvas-card__media {
    background-color: $bg-lime;
  }
  &:hover {
    .canvas-card__media {
      @include elevate(12, $color-lime);
    }
    .canvas-card__delete {
      @include elevate(2, $color-lime);
    }
  }
}


// Canvas Mockup for Loading State
.canvas-card_mock {
  $color: $color-gray0;
  $color2: $color-gray8;

  animation: none;

  &:hover .canvas-card__media {
    transform: none;
    box-shadow: none;
  }

  .canvas-card__media {
    padding: 80% 0 0;
    background: $color;
    animation: 3s canvas-card-loading infinite;
  }

  .canvas-card__title,
  .canvas-card__type {
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      background-color: $color;
      animation: 3s canvas-card-loading infinite;
    }
  }

  .canvas-card__title {
    &:before {
      height: 0.8rem;
      width: 85%;
    }
  }

  .canvas-card__type {
    &:before {
      height: 0.66rem;
      width: 50%;
    }
  }

  @keyframes canvas-card-loading {
    0% {
      background-color: $color;
    }
    50% {
      background-color: $color2;
    }
    100% {
      background-color: $color;
    }
  }
}


//
// Card Deletion
// =============
.canvas-card__delete {
  color: $color-gray4;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  right: -.75rem;
  top: -1.375rem;
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0;
  background: #fff;
  border-radius: 1.5rem;
  cursor: pointer;
  user-select: none;
  will-change: opacity, box-shadow;
  transition: box-shadow 150ms ease, opacity 150ms ease;
  @include elevate(2, $color-gray3);
}
.canvas-card:hover {
  .canvas-card__delete {
    opacity: 1;
  }
}
.canvas-card__delete:active {
  color: $color-red;
}
.canvas-card__delete:focus {
  outline: none;
}
.canvas-card__confirming-remove {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(#fff, .85);
}
.canvas-card__confirming-remove-inner {}

.canvas-card_removing {
  opacity: .2;
}
.canvas-card_removing,
.canvas-card_removing-confirm {
  &:hover .canvas-card__media {
    transition: none;
    transform: none;
    box-shadow: none;
  }
}


@media (min-width: $min-tablet) {
  .canvas-card__title {
    font-size: 1.125rem;
  }
}
